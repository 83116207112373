import React from "react";
import KTabPanel from "../tabpanel";
import { CardContent, Card, Stack, TabPanel, Button } from "@mui/joy";
import KTable from "../../components/table";
import { useAPI } from "../../controllers/ContextProvider";
import { FloatingLabelInput, KSelect } from "../../components/input";
import { Grid } from "@mui/material";

function UsersPanel() {
    const [users, setUsers] = React.useState([]);
    let { get } = useAPI();
    React.useEffect(() => {
        get('users/list').then((r) => {
            setUsers(r.data)
        })

    }, []);
    return <KTabPanel tabheads={['All Users', 'Create User']} tabcomps={
        [<TabPanel value={0}>
            <KTable rows={users} columns={[
                { field: 'name', headerName: 'Full name', width: 300 },
                { field: 'email', headerName: 'Email Address', width: 300 },
                { field: 'created_at', headerName: 'Created Date', width: 300 }
            ]} />
        </TabPanel>,
        <TabPanel value={1}>
            <Stack>
                <CreateUser />
            </Stack>
        </TabPanel>]
    } />;
}

function CreateUser() {
    let { get, post } = useAPI();
    const [name, sname] = React.useState(null)
    const [email, semail] = React.useState(null)
    const [group, sgroup] = React.useState(null)
    const [pwd, spwd] = React.useState(null)
    const [groups, setGroups] = React.useState([]);
    const onsubmit = () => {
        post('auth/register', {
            name: name,
            email: email,
            password: pwd,
            group: group
        })
    };
    React.useEffect(() => {
        get('users/list/groups').then((r) => {
            if(r.status){
                setGroups(r.data)
            }
        })
    }, []);
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={4}>

            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <Card>
                    <CardContent>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                        }}>
                            <FloatingLabelInput onChange={(e) => { sname(e.target.value) }} label={"Full name"} type={"text"} />
                            <FloatingLabelInput onChange={(e) => { semail(e.target.value) }} label={"Email Address"} type={"email"} />
                            <FloatingLabelInput onChange={(e) => { spwd(e.target.value) }} label={"Password"} type={"pwd"} />
                            <KSelect onChange={(e, v) => { sgroup(v) }} options={groups} style={{ marginBottom: 10 }} />
                            <Button fullWidth title="asd" color="primary" onClick={onsubmit}>Create user</Button>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

export default UsersPanel;

import * as React from "react";
import KTable from "../../components/table";
import { useAPI } from "../../controllers/ContextProvider";
import { Button, Card, CardContent, Stack } from "@mui/joy";
import FormPane from "../forms/form_pane";
import { useNavigate } from "react-router";
import { MoreFour } from "@icon-park/react";
import { GridActionsCellItem } from "@mui/x-data-grid";
export default function ProjectList() {
  let { get, setTopics, updateFormRequest, setAdditionalFormData, business } =
    useAPI();
  const [forms, setForms] = React.useState([]);
  let nav = useNavigate();
  const [show, showForm] = React.useState(false);
  React.useEffect(() => {
    get("forms/list/projects")
      .then((r) => {
        setForms(r.data);
        setTopics(r.data);
      })
      .catch((e) => {
      });
  }, []);
  return (
    <div>
      <Stack direction={"row"}>
        <Button
          onClick={() => {
            updateFormRequest(0, 8);
            setAdditionalFormData({ business: business.id });
          }}
        >
          Create a Project
        </Button>
      </Stack>
      <br />
      {show ? (
        <FormPane
          onClose={() => {
            showForm(false);
          }}
        ></FormPane>
      ) : null}
      <Card>
        <CardContent>
          <KTable
            onRowClick={(e) => {
              nav("/forms/project", { state: e.row });
            }}
            columns={[
              { field: "id", headerName: "ID" },
              { field: "name", headerName: "Project name", width: 300 },
              { field: "location", headerName: "Location", width: 300 },
              { field: "description", headerName: "Description", width: 300 },
              { field: "price", headerName: "Price", width: 300 },
              { field: "total_expenditure", headerName: "Amount Spent" },
              { field: "component", headerName: "Amount Spent", width: 300 },
            ]}
            rows={forms}
          />
        </CardContent>
      </Card>
    </div>
  );
}

import * as React from "react";
import {
  DataGrid,
  useGridApiRef,
  GridActionsCellItem,
  DEFAULT_GRID_AUTOSIZE_OPTIONS,
} from "@mui/x-data-grid";
import { useAPI } from "../controllers/ContextProvider";
import { Edit, MoreOne, Open, Scale } from "@icon-park/react";
import {
  Button,
  CssVarsProvider,
  Dropdown,
  extendTheme,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  useColorScheme,
} from "@mui/joy";
import { Icon } from "leaflet";
import { FormActions } from "../panels/forms/feedbackPreview";
import {
  Experimental_CssVarsProvider as CssVarsProviderV,
  experimental_extendTheme as _extendTheme,
} from "@mui/material/styles";

const baseTheme = extendTheme();

const darkTheme = _extendTheme({
  colorSchemes: {
    light: baseTheme.colorSchemes.light,
    dark: baseTheme.colorSchemes.dark,
  },
  cssVarPrefix: "oljh",
});

export function DataGridTheme({ children }) {
  let { mode } = useColorScheme();
  return (
    <CssVarsProvider
      defaultMode={mode}
      defaultColorScheme={mode}
      theme={darkTheme}
    >
      {children}
    </CssVarsProvider>
  );
}
export default function KTable({
  columns,
  rows,
  actions = [],
  onRowClick = () => {},
  minHeight = window.innerHeight / 1.3,
  editIcon = false,
  onEditClick = () => {},
}) {
  const [includeHeaders, setIncludeHeaders] = React.useState(
    DEFAULT_GRID_AUTOSIZE_OPTIONS.includeHeaders
  );
  const [includeOutliers, setExcludeOutliers] = React.useState(
    DEFAULT_GRID_AUTOSIZE_OPTIONS.includeOutliers
  );
  const [outliersFactor, setOutliersFactor] = React.useState(
    String(DEFAULT_GRID_AUTOSIZE_OPTIONS.outliersFactor)
  );
  const [expand, setExpand] = React.useState(
    DEFAULT_GRID_AUTOSIZE_OPTIONS.expand
  );

  const autosizeOptions = {
    includeHeaders,
    includeOutliers,
    outliersFactor: Number.isNaN(parseFloat(outliersFactor))
      ? 1
      : parseFloat(outliersFactor),
    expand,
  };
  React.useEffect(() => {
    // apiRef.current.updateRows(rows);
  }, [rows]);
  const { mode } = useColorScheme();
  return (
    <div>
      <DataGridTheme>
        <DataGrid
          sx={{
            border: `1px solid ${baseTheme.colorSchemes[mode].palette.neutral.softActiveBg}`,
          }}
          slotProps={{
            cell: { columnResize: true, isEditable: true },
            row: {
              style: {
                color: baseTheme.colorSchemes[mode].palette.text.primary,
                borderTop: `1px solid ${baseTheme.colorSchemes[mode].palette.neutral.softActiveBg}`,
              },
            },
            footer: {
              style: {
                borderColor:
                  baseTheme.colorSchemes[mode].palette.neutral.softActiveBg,
              },
            },
            panel: {
              style: {
                borderColor:
                  baseTheme.colorSchemes[mode].palette.neutral.softActiveBg,
              },
            },
          }}
          editMode="cell"
          rowBuffer={1}
          autoSizeOptions={autosizeOptions}
          // onCellClick={onRowClick}
          rows={rows}
          // style={{ minHeight: minHeight, }}
          columns={[
            ...columns,
            {
              field: "actions",
              // type: "actions",
              headerName: "Actions",
              width: 100,
              cellClassName: "actions",
              renderCell: (params) => {
                return (
                  <CssVarsProvider
                    theme={baseTheme}
                    modeStorageKey="mode-toggle-demo"
                  >
                    <Stack
                      position={"sticky"}
                      spacing={1}
                      top={0}
                      right={0}
                      direction="row"
                    >
                      <IconButton
                        onClick={() => {
                          onRowClick(params);
                        }}
                      >
                        <Scale />
                      </IconButton>
                      {editIcon ? (
                        <IconButton
                          onClick={() => {
                            onEditClick(params);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      ) : null}
                      <FormActions actions={actions} tag={params.row.id} />
                    </Stack>
                  </CssVarsProvider>
                );
              },
            },
          ]}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[100, 300, 1000]}
        />
      </DataGridTheme>
    </div>
  );
}

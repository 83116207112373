import * as React from "react";
import {
  Card,
  Button,
  Typography,
  Divider,
  Input,
  Switch,
  CardActions,
  IconButton,
  Menu,
  MenuItem,
  MenuButton,
  Dropdown,
  Sheet,
  Grid,
  Stack,
} from "@mui/joy";
import { Loading } from "../../components/loader";
import KTable from "../../components/table";
import { DSelect, GetInput } from "./formbuilder";
import { useAPI } from "../../controllers/ContextProvider";
import { KSelect } from "../../components/input";
import { Delete, Edit, Plus, Save } from "@icon-park/react";
import { AddItem } from "@icon-park/react/es/map";
import { useLocation, useNavigate } from "react-router";
import { KUtility } from "../../components/utility";

export default function FormCreator() {
  const [forms, setForms] = React.useState([]);
  let nav = useNavigate();
  let { get } = useAPI();
  React.useEffect(() => {
    get("forms/list/all").then((r) => {
      if (r.status) {
        setForms(r.data);
      }
    });
  }, []);
  const onEdit = (e) => {
    Loading.setMessage("Preparing your form");
    get("forms/getForm/" + e.id).then((r) => {
      if (r.status) {
        nav("/forms/studio", {
          state: {
            forms: forms,
            _fields: r.data.fields[0],
            topic: r.data.topic,
            edit: true,
          },
        });
      }
    });
  };
  return (
    <Card>
      <Stack direction={"row"}>
        <Button
          onClick={() => {
            nav("/forms/studio", {
              state: {
                forms: forms,
                _fields: [],
                topic: {
                  name: "",
                  id: "",
                  type: "",
                  definition: "",
                  description: "",
                  attributes: { model_id: "", model_name: "", listTxt: "" },
                },
                edit: false,
              },
            });
          }}
        >
          Create Form
        </Button>
      </Stack>
      <KTable
        rows={forms}
        columns={[
          { field: "id", headerName: "Id", resizable: true },
          { field: "name", headerName: "Name", width: 400, resizable: true },
          { field: "type", headerName: "Type", width: 400, resizable: true },
          {
            field: "created_at",
            headerName: "Created date",
            resizable: true,
          },
        ]}
        editIcon={true}
        onEditClick={onEdit}
      />
    </Card>
  );
}

export function FormStudio() {
  const [existingFields, setExistingFields] = React.useState([]);
  const [actions, setActions] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [, forceUpdate] = React.useState();
  const { forms, _fields, topic, edit } = useLocation().state;
  const [fields, setFields] = React.useState(_fields);
  let { get, buildForm, appConfig, post } = useAPI();
  const [fieldData, setFieldData] = React.useState({
    createTxt: topic.name,
    modelId: topic.attributes.model_id,
    modelName: topic.attributes.model_name,
    description: topic.description,
    type: topic.type,
    code: topic.code,
    helperTxt: topic.definition,
    listText: topic.attributes.listTxt,
    actions: [],
    childForms: topic.childForms,
  });
  const onChange = (e, v) => {
    let p = fieldData;
    p[e] = v;
    setFieldData(p);
  };
  const addNewField = (type, template = null) => {
    let f = fields;
    if (template !== null) {
      f.push(template);
    } else {
      f.push(getInputTemplate(type, true, false, appConfig.formConfig));
    }
    setFields(f);
    forceUpdate({});
  };
  const selectType = (i) => {
    types.forEach((f) => {
      if (f.id == i) {
        addNewField(f);
      }
    });
    forceUpdate({});
  };
  const addExistingField = (selectedField) => {
    existingFields.forEach((t) => {
      if (t.id == selectedField) {
        fields.push(getInputTemplate(t, false, false, appConfig.formConfig, t));
        setFields(fields);
        forceUpdate({});
      }
    });
  };
  const updateField = (data, key) => {
    let f = fields;
    f[key] = data;
    setFields(f);
    forceUpdate({});
  };
  const removeField = (key) => {
    forceUpdate({});
    let p = fields;
    p[key].deleted = true;
    setFields(p);
    forceUpdate({});
  };
  const getTypes = () => {
    get("forms/list/fieldtypes").then((r) => {
      if (r.status) {
        let p = r.data;
        let data = [];
        p.forEach((t) => {
          t["value"] = t.id;
          data.push(t);
        });
        setTypes(data);
        forceUpdate({});
      }
    });
  };
  const getOptions = () => {
    get("forms/list/options").then((r) => {
      if (r.status) {
        let p = r.data;
        let data = [];
        p.forEach((t) => {
          t["value"] = t.id;
          data.push(t);
        });
        setOptions(data);
      }
    });
  };
  const getFields = () => {
    get("forms/list/fields").then((r) => {
      if (r.status) {
        let p = [];
        r.data.forEach((prop, key) => {
          prop["value"] = prop.id;
          p.push(prop);
        });
        setExistingFields(p);
        forceUpdate({});
      }
    });
  };
  const getActions = () => {
    get("forms/list/actions").then((r) => {
      if (r.status) {
        setActions(r.data);
      }
    });
  };
  const onSubmit = () => {
    post("forms/create/form", {
      ...fieldData,
      fields: fields,
      editing: edit,
      form: topic.id,
    }).then((r) => {});
  };
  React.useEffect(() => {
    getTypes();
    getOptions();
    getFields();
    getActions();
    forceUpdate({});
  }, []);
  const showHideFormDetails = () => {
    let d = document.getElementById("formdetail");
    d.style.display = d.style.display === "flex" ? "none" : "flex";
  };
  return (
    <div
      style={{
        height: window.innerHeight - 90,
        overflow: "scroll",
        width: "100%",
        padding: 0,
      }}
    >
      <Sheet style={{ position: "sticky", top: 0, zIndex: 5 }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          spacing={1}
          padding={2}
        >
          <Stack direction={"row"} spacing={1}>
            <Dropdown>
              <MenuButton
                slots={{ root: Button }}
                slotProps={{ root: { variant: "outlined", color: "default" } }}
              >
                <Stack
                  padding={1}
                  spacing={1}
                  alignItems={"center"}
                  direction={"row"}
                >
                  <Plus />
                  <Typography>Create</Typography>
                </Stack>
              </MenuButton>
              <Menu>
                <MenuItem
                  onClick={() => {
                    buildForm(appConfig.formConfig.addType);
                  }}
                >
                  Type
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    buildForm(appConfig.formConfig.action_form);
                  }}
                >
                  Action
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    buildForm(appConfig.formConfig.option_form);
                  }}
                >
                  Option list
                </MenuItem>
                {/* <MenuItem></MenuItem> */}
              </Menu>
            </Dropdown>
            <Button onClick={showHideFormDetails} color="default">
              <Stack
                direction={"row"}
                padding={1}
                spacing={1}
                alignItems={"center"}
              >
                <AddItem />
                <Typography>Form details</Typography>
              </Stack>
            </Button>
          </Stack>
          <Button startDecorator={<Save />} onClick={onSubmit} color="primary">
            <Stack
              direction={"row"}
              padding={1}
              spacing={1}
              alignItems={"center"}
            >
              Save
            </Stack>
          </Button>
        </Stack>
        <div
          className="animate__animated animate__fadeInTop"
          id="formdetail"
          style={{ display: "flex" }}
        >
          <Grid spacing={2} container>
            <Grid item xs={12} md={9}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <Stack spacing={1}>
                    <Input
                      type="text"
                      size="lg"
                      defaultValue={topic.attributes.model_name}
                      onChange={(e) => {
                        onChange("modelName", e.target.value);
                      }}
                      placeholder="Model name"
                    />
                    <Input
                      type="text"
                      size="lg"
                      defaultValue={topic.name}
                      placeholder="Create message"
                      onChange={(e) => {
                        onChange("createTxt", e.target.value);
                      }}
                    />
                    <Input
                      type="text"
                      size="lg"
                      defaultValue={topic.description}
                      placeholder="Description"
                      onChange={(e) => {
                        onChange("description", e.target.value);
                      }}
                    />
                    <DSelect
                      defaultValue={topic.actions}
                      e={{
                        id: "te",
                        name: "Actions",
                        options: actions,
                        multiple: "1",
                      }}
                      onChange={(e, v) => {
                        onChange("actions", v);
                      }}
                    />
                    <DSelect
                      defaultValue={topic.actions}
                      e={{
                        id: "te",
                        name: "Form Type",
                        options: [
                          { id: "form", name: "Form", value: "form" },
                          { id: "api", name: "API Request", value: "api" },
                        ],
                        multiple: "0",
                      }}
                      onChange={(e, v) => {
                        onChange("type", v);
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4} paddingRight={3}>
                  <Stack spacing={1}>
                    <Input
                      type="text"
                      size="lg"
                      defaultValue={topic.attributes.model_id}
                      placeholder="Model id"
                      onChange={(e) => {
                        onChange("modelId", e.target.value);
                      }}
                    />
                    <Input
                      type="text"
                      size="lg"
                      placeholder="List message"
                      defaultValue={"List of " + topic.attributes.listTxt}
                      onChange={(e) => {
                        onChange("listTxt", e.target.value);
                      }}
                    />
                    <Input
                      type="text"
                      size="lg"
                      // defaultValue={}
                      placeholder="Helper text"
                      onChange={(e) => {
                        onChange("helperTxt", e.target.value);
                      }}
                    />
                    <Input
                      type="text"
                      size="lg"
                      defaultValue={topic.code}
                      placeholder="Code"
                      onChange={(e) => {
                        onChange("code", e.target.value);
                      }}
                    />
                    <DSelect
                      onChange={(e, v) => {
                        onChange("childForms", v);
                      }}
                      e={{
                        id: "tef",
                        name: "Child forms",
                        options: forms,
                        multiple: "1",
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Sheet>
      <Grid container>
        <Grid item xs={12} md={8}>
          <div style={{}}>
            <Grid spacing={1} container padding={2}>
              {fields.map((prop, key) =>
                !prop.deleted ? (
                  <Grid
                    item
                    xs={prop.styles.xs}
                    md={prop.styles.md}
                    sm={prop.styles.sm}
                    lg={prop.styles.lg}
                    xl={prop.styles.xl}
                  >
                    <FormElement
                      types={types}
                      data={() => {
                        if (
                          prop.style_rules === undefined ||
                          prop.rules === undefined
                        ) {
                          prop.style_rules = getStylesArr(
                            ["xs", "sm", "md", "lg", "xl"],
                            prop.styles
                          );
                          // prop.rules = prop.c_rules;
                        }
                        return prop;
                      }}
                      forms={forms}
                      fields={existingFields}
                      getOptions={getOptions}
                      options={options}
                      removeField={() => {
                        console.log(fields);
                        removeField(key);
                      }}
                      onChange={(data) => {
                        updateField(data, key);
                      }}
                    />
                  </Grid>
                ) : null
              )}
            </Grid>
            <Grid marginTop={2} container spacing={1} justifyContent={"center"}>
              <Grid item xs={12}>
                <Typography textAlign={"center"}>
                  Add a new field or choose an existing field
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={3}>
                <DSelect
                  e={{
                    id: "as",
                    name: "Choose field type",
                    options: types,
                  }}
                  options={types}
                  onChange={(e, v) => {
                    selectType(v);
                  }}
                  name={"Choose Field type"}
                />
              </Grid>
              <Grid item xs={3}>
                <DSelect
                  onChange={(e, v) => {
                    addExistingField(v);
                  }}
                  e={{
                    name: "Existing field",
                    id: "0",
                    options: existingFields,
                  }}
                  options={existingFields}
                  name={"Existing field"}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>
      <div style={{ height: 400 }}></div>
    </div>
  );
}
function FormElement({
  data = () => {},
  onChange,
  types,
  removeField,
  isChild = false,
  isNew = true,
  getOptions = () => {},
  options = [],
  fields = [],
  forms = [],
}) {
  const [field, setField] = React.useState(data());
  console.log(data());
  const [showEdit, setShowEdit] = React.useState(false);
  const [, forceUpdate] = React.useState();
  let { appConfig } = useAPI();
  const updateStyles = (key, val) => {
    let p = field;
    p.styles[key] = val;
    setField(p);
    onChange(p);
    forceUpdate({});
  };
  const updateRules = (val) => {
    let p = field;
    // p.rules = val;
    p.c_rules = val;
    p.rules = val;
    console.log(p);

    setField(p);
  };
  const updateNormal = (key, val) => {
    let p = field;
    p[key] = val;
    setField(p);
    onChange(p);
    console.log(p);
    forceUpdate({});
  };
  const updateType = (i) => {
    types.forEach((f) => {
      if (f.id === i) {
        setField(
          getInputTemplate(f, isNew, isChild, field, appConfig.formConfig)
        );
        onChange(field);
      }
      forceUpdate({});
    });
  };
  const updateOptions = (val) => {
    options.forEach((f) => {
      if (f.value === val) {
        let p = field;
        let px = [];
        f.options.forEach((j) => {
          px.push({ name: j, value: j });
        });
        p.options = px;
        p["selectedOption"] = val;
        setField(p);
        onChange(p);
        forceUpdate({});
      }
    });
  };
  const updatePairFields = () => {
    let p = [];
    if (field.c_rules.pairs !== undefined) {
      field.c_rules.pairs.forEach((t) => {
        p.push(KUtility.getById(fields, "map_name", t));
      });
    }
    let x = field;
    x["pairs"] = p;
    setField(x);
    console.log(field);
    onChange(x);
    return p;
  };
  return (
    <div>
      <Card>
        <GetInput
          input={field}
          multiple={field.Multiple}
          onChange={() => {}}
          type={field.type.name}
        />
        {showEdit ? (
          <Grid
            style={{ position: "fixed", top: 150, left: "72%", right: 25 }}
            spacing={1}
            container
          >
            <Grid item xs={12} md={12}>
              <Stack spacing={1}>
                <Divider />
                <Stack direction={"row"} spacing={1}>
                  <Input
                    defaultValue={field.name}
                    onChange={(e) => {
                      updateNormal("name", e.target.value);
                    }}
                    placeholder="Display Name"
                  />
                  <Input
                    defaultValue={field.map_name}
                    placeholder="Mapping Name"
                    onChange={(e) => {
                      updateNormal("map_name", e.target.value);
                    }}
                  />
                </Stack>
                <Input
                  defaultValue={field.error_txt}
                  placeholder="Error message"
                  onChange={(e) => {
                    updateNormal("error_txt", e.target.value);
                  }}
                />
                <Stack direction={"row"} spacing={1}>
                  <DSelect
                    e={{ options: types, name: "Type", id: "d0" }}
                    defaultValue={field.type}
                    onChange={(e, v) => {
                      updateType(v);
                    }}
                  />
                  <DSelect
                    e={{ id: "0", name: "Choose Options", options: options }}
                    onChange={(e, v) => {
                      updateOptions(v);
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack spacing={1}>
                <Typography>Styles & Rules</Typography>
                <Divider />
                <ShowRules
                  fields={fields}
                  forms={forms}
                  rules={field.rules}
                  updateRules={updateRules}
                  updatePairs={(val) => {
                    updateNormal("pairs", val);
                  }}
                />
                <Button
                  onClick={() => {
                    updatePairFields();
                  }}
                >
                  Update rules
                </Button>
                <Stack direction={"row"} spacing={1}>
                  {field.style_rules.map((prop, key) => (
                    <Input
                      placeholder={prop.key}
                      defaultValue={prop.value}
                      onChange={(e) => {
                        updateStyles(prop.key, e.target.value);
                      }}
                    />
                  ))}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        ) : null}
        <CardActions>
          <Stack direction={"row"}>
            <IconButton
              size="small"
              onClick={() => {
                setShowEdit(!showEdit);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                removeField();
              }}
            >
              <Delete />
            </IconButton>
          </Stack>
        </CardActions>
      </Card>
    </div>
  );
}
function ShowRules({
  rules,
  updateRules,
  fields,
  forms,
  updatePairs = () => {},
}) {
  const updateRule = (name, value) => {
    let it = [];
    rules.forEach((t) => {
      if (t.key === name) {
        t["value"] = value;
      }
      it.push(t);
    });
    // setVal(val==="0"? "1":"0")
    updateRules(it);
  };
  const [rule, setRule] = React.useState("");
  const [ruleType, setRuleType] = React.useState("");
  const [, forceUpdate] = React.useState();
  const addRule = () => {
    let p = rules;
    p.push({ key: rule, value: "", type: ruleType });
    updateRules(p);
    forceUpdate({});
  };

  const RuleView = ({ data }) => {
    const [val, setVal] = React.useState(data.value);
    const getMapNames = (v, id, data) => {
      let p = [];
      v.forEach((t) => {
        p.push(KUtility.getById(data, id, t)["map_name"]);
      });
      return p;
    };
    switch (data.type) {
      case "boolean":
        return (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>{data.key}</Typography>
            <Switch
              checked={val === "1"}
              // defaultChecked={data.value==="1"}
              onChange={(e) => {
                updateRule(data.key, val === "0" ? "1" : "0");
                setVal(val === "0" ? "1" : "0");
              }}
              placeholder={data.key}
            />
          </Stack>
        );
      case "single-field":
        return (
          <DSelect
            onChange={(e, v) => {
              updateRule(data.key, getMapNames([v], "id", fields)[0]);
            }}
            e={{
              name: "Choose field",
              id: "0",
              options: fields,
            }}
            options={fields}
            name={"Existing field"}
          />
        );
      case "multiple-fields":
        return (
          <DSelect
            onChange={(e, v) => {
              updateRule(data.key, getMapNames(v, "id", fields));
            }}
            e={{
              name: "Choose fields",
              id: "0",
              options: fields,
              multiple: "1",
            }}
            options={fields}
            name={"Existing field"}
          />
        );
      case "single-form":
        return (
          <DSelect
            onChange={(e, v) => {
              updateRule(data.key, getMapNames([v], "id", forms)[0]);
            }}
            e={{
              name: "Choose form",
              id: "0",
              options: forms,
            }}
          />
        );
      case "multiple-forms":
        return (
          <DSelect
            onChange={(e, v) => {
              updateRule(data.key, getMapNames([v], "id", fields)[0]);
            }}
            e={{
              name: "Choose Model form",
              id: "0",
              options: forms,
              multiple: "1",
            }}
            options={forms}
            name={"Existing field"}
          />
        );
      case "autocalc":
        return (
          <DSelect
            onChange={(e, v) => {
              if (["+", "-", "", "divide"].includes(v)) updateRule(data.key, v);
            }}
            e={{
              name: "Choose fields & operators",
              id: "0",
              options: [
                ...[
                  { id: "sum", name: "+" },
                  { id: "subtract", name: "-" },
                  { id: "multiply", name: "*" },
                  { id: "divide", name: "" },
                ],
                ...fields,
              ],
              multiple: "1",
            }}
          />
        );
      default:
        return (
          <Input
            defaultValue={data.value === undefined ? data.item : data.value}
            placeholder={data.key}
            onChange={(e) => {
              updateRule(data.key, e.target.value);
            }}
          />
        );
    }
  };
  return (
    <div>
      <Stack spacing={1}>
        {rules.map((prop, key) => (
          <RuleView data={prop} />
        ))}
        <Stack direction={"row"} spacing={1}>
          <KSelect
            onChange={(e, v) => {
              setRuleType(v);
              forceUpdate({});
            }}
            name={"Type"}
            options={[
              {
                name: "boolean",
                value: "boolean",
                id: "boolean",
              },
              {
                name: "string",
                value: "string",
                id: "string",
              },
              {
                name: "single-field",
                value: "single-field",
                id: "single-field",
              },
              {
                name: "multiple-fields",
                value: "multiple-fields",
                id: "multiple-fields",
              },
              {
                name: "single-form",
                value: "single-form",
                id: "single-form",
              },
              {
                name: "multiple-forms",
                value: "multiple-forms",
                id: "multiple-forms",
              },
            ]}
          ></KSelect>
          <Input
            placeholder={"Add rule"}
            onChange={(e) => {
              setRule(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              addRule();
            }}
            variant="outlined"
          >
            Add Rule
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}

const getInputTemplate = (
  type,
  isNew = true,
  child = false,
  formConfig = {},
  defaultValue = {
    name: "Untitled",
    map_name: "",
    error_txt: "This field is required",
    style_rules: [
      { key: "xs", value: "12" },
      { key: "sm", value: "12" },
      { key: "md", value: "12" },
      { key: "lg", value: "12" },
      { key: "xl", value: "12" },
    ],
    rules: [
      { key: "Required", value: "1", type: "boolean" },
      { key: "Visible", value: "1", type: "boolean" },
      { key: "Multiple", value: "0", type: "boolean" },
      { key: "editable", value: "0", type: "boolean" },
      { key: "showWhenParentForm", value: "1", type: "boolean" },
      { key: "showWhenChildForm", value: "0", type: "boolean" },
    ],
  }
) => {
  if (!isNew) {
    let p = defaultValue;
    p["style_rules"] = [
      { key: "xs", value: "12" },
      { key: "sm", value: "12" },
      { key: "md", value: "12" },
      { key: "lg", value: "12" },
      { key: "xl", value: "12" },
    ];
    p["map_name"] = "";
    p["isNew"] = false;
    return p;
  }
  let initTemplate = {
    id: 0,
    name: defaultValue.name,
    map_name: defaultValue.map_name,
    isNew: true,
    deleted: false,
    error_txt: "This field is required",
    type: type,
    options: [],
    style_rules: defaultValue.style_rules,
    styles: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    rules: defaultValue.rules,
  };
  let isChild = [
    { key: "parent", value: "value", type: "boolean" },
    { key: "parent_element", value: "value", type: "single-field" },
    { key: "parent_value", value: "value", type: "string" },
  ];
  if (child) {
    initTemplate.rules = initTemplate.rules.concat(isChild);
  }
  switch (type.name) {
    case "gpsimage":
    case "file":
    case "image":
      initTemplate.rules = initTemplate.rules.push({
        key: "accept",
        value: "image/*",
        type: "string",
      });
      break;
    case "DatabaseSelect":
      initTemplate.rules = initTemplate.rules.concat([
        { key: "db", value: "answers", type: "string" },
        { key: "topic", value: "", type: "single-form" },
        { key: "field", value: "", type: "single-field" },
      ]);
      break;
    case "Pair":
      initTemplate["pairs"] = [];
      initTemplate.rules = initTemplate.rules.concat([
        {
          key: "pairs",
          value: [],
          type: "multiple-fields",
        },
      ]);
      break;
    case "AutoCalc":
      initTemplate.rules = initTemplate.rules.concat([
        { key: "Fields", value: [], type: "autocalc" },
      ]);
    default:
      break;
  }
  let p = {};
  initTemplate.rules.map((prop) => {
    p[prop.key] = prop.value;
  });
  initTemplate["c_rules"] = p;
  return initTemplate;
};

const getStylesArr = (keys, arr) => {
  let p = [];
  keys.forEach((t) => {
    p.push({ key: t, value: arr[t] });
  });
  return p;
};

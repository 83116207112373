import * as React from 'react';
import { styled } from '@mui/joy/styles';
import Input from '@mui/joy/Input';
import { Select, Option } from '@mui/joy';
import {useAPI} from "../controllers/ContextProvider";
import {DSelect} from "../panels/forms/formbuilder";
const StyledInput = styled('input')({
    border: 'none', // remove the native input border
    minWidth: 0, // remove the native input width
    outline: 0, // remove the native input outline
    padding: 0, // remove the native input padding
    paddingTop: '1em',
    flex: 1,
    color: 'inherit',
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    textOverflow: 'ellipsis',
    '&::placeholder': {
        opacity: 0,
        transition: '0.1s ease-out',
    },
    '&:focus::placeholder': {
        opacity: 1,
    },
    '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label': {
        top: '0.5rem',
        fontSize: '0.75rem',
    },
    '&:focus ~ label': {
        color: 'var(--Input-focusedHighlight)',
    },
    '&:-webkit-autofill': {
        alignSelf: 'stretch', // to fill the height of the root slot
    },
    '&:-webkit-autofill:not(* + &)': {
        marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
        paddingInlineStart: 'var(--Input-paddingInline)',
        borderTopLeftRadius:
            'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
        borderBottomLeftRadius:
            'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    },
});

const StyledLabel = styled('label')(({ theme }) => ({
    position: 'absolute',
    lineHeight: 1,
    top: 'calc((var(--Input-minHeight) - 1em) / 2)',
    color: theme.vars.palette.text.tertiary,
    fontWeight: theme.vars.fontWeight.md,
    transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}));

const InnerInput = React.forwardRef(function InnerInput(props, ref) {
    const id = React.useId();
    // let inp = document.getElementById(id);
    return (
        <React.Fragment>
            <StyledInput  min={0} {...props} ref={ref} id={id} />
            <StyledLabel style={{paddingStart:110}} htmlFor={id}>{props.placeholder}</StyledLabel>
        </React.Fragment>
    );
});
const Blank=()=>{
    return(
        <div>

        </div>
    )
}

function FloatingLabelInput({ type, label, onChange, required,name,StartDecorator =  Blank,min}) {
    return (
        <Input
            required={required}
            startDecorator={<StartDecorator/>}
            aria-valuemin={min}
            // name={name}
            // name={name}
            // endDecorator={<CheckCircleOutlined />}
            slots={{ input: InnerInput }}
            onChange={onChange}
            style={{ marginBottom: 5 }}
            // onChange={onChange}
            slotProps={{ input: { placeholder: label, type: type, name: name } }}
            sx={{
                'backgroundColor' : 'unset',
                '--Input-minHeight' : '54px',
                '--Input-radius' : '5px',
            }}
        />
    );
}
function KSelect({ name, options, onChange, style, required, valueField }) {
    return (
        <Select sx={{":required":required}} size='lg' multiple onChange={onChange} style={style} placeholder={name} name='kk'>
            {
                options.map((prop, key) => (
                    <Option key={key} value={prop.value}>{prop.name}</Option>
                ))
            }
        </Select>
    )
}
function LocalitySelector({type,e,onChange}) {
    let {get} = useAPI();
    const [regions, setRegions] = React.useState([]);
    const [districts, setDistricts] = React.useState([]);
    const [councils, setCouncils] =React.useState([]);
    const [wards, setWards] =React.useState([]);
    const [streets, setStreets] =React.useState([]);
    const [,forceUpdate] =React.useState();
    const purify = (d) =>{
        let p= [];
        d.forEach(t=>{
            p.push({value: t.id, label: t.name});
        })
        return p;
    }
    const getRegions = () =>{
        get('locality/regions/list/all').then(r=>{
            setRegions(r.data)
            forceUpdate({})
        })
    }
    const getDistricts = (region)=>{
        get(`locality/districts/list/${region}`).then(r=>{
            setDistricts(r.data)
            forceUpdate({})
        })
    }
    const getCouncils= (district)=>{
        get(`locality/councils/list/${district}`).then(r=>{
            setCouncils(r.data)
            forceUpdate({})
        })
    }
    const getWards = (council)=>{
        get(`locality/wards/list/${council}`).then(r=>{
            setWards(r.data)
            forceUpdate({})
        })
    }
    const getPlaces=(ward)=>{
        get(`locality/streets/list/${ward}`).then(r=>{
            setStreets(r.data)
            forceUpdate({})
        })
    }
    const localityChange = (typeS,name,value) =>{
        if(type===typeS){
            onChange('g'+e.id,value);
        }
        else{
        }
    }
    const selectDeps = {
        region:[],
        districts:["district","ward","street"],
        wards:["ward","street"],
        streets:["street"],

    }
    React.useEffect(()=>{
        getRegions();
    },[])
    return(
        <div>
            <p>Choose location ({e.name})</p>
            <DSelect e={{id:'region',name:"Region", options:regions, required: 1, multiple: "0"}} onChange={(n,v)=>{
                localityChange("district",n,v)
                getDistricts(v);
            }}/>
            {selectDeps.districts.includes(type)?<DSelect
                e={{id:'district',name:"District", options:districts, required: 1, multiple: "0"}}
                onChange={(n,v)=>{
                    localityChange("district",n,v)
                    getWards(v);
                }}/>:null}
            {selectDeps.wards.includes(type)?<DSelect
                e={{id:'ward',name:"Ward", options:wards, required: 1, multiple: "0"}}
                onChange={(n,v)=>{
                    localityChange("ward",n,v)
                    getPlaces(v)
                }}/>:null}
            {selectDeps.streets.includes(type)?<DSelect e={{id:'street',name:"Mtaa", options:streets, required: 1, multiple: "0"}}  onChange={(n,v)=>{localityChange("street",n,v)}}/>:null}
        </div>
    )
}
export { FloatingLabelInput, KSelect, LocalitySelector }
// import {  } from '@mui/joy'
import { Grid, Stack, Typography } from '@mui/material'
import * as React from 'react'
import KIcon from '../../components/iconifier'

export default function FormPane({ data, title, children, description, onClose }) {
    return (
        <div style={{  maxHeight:window.innerHeight-100, overflow: "auto", overflowX: "none" }}>
            <div style={{  zIndex: 32, position: "fixed",top:0,left:0,right:0 }}>
                <Grid container>
                    <Grid item xs={1} md={3} lg={3}></Grid>
                    <Grid item xs={12} md={6} lg={6}>

                        {/* <Stack style={{ backgroundColor: "#fff",boxShadow: "0px 0px 10px #999", }} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <div></div>
                            <div onClick={onClose} style={{ padding: "10px 20px", backgroundColor: "#fff", cursor: 'pointer' }}>
                                <KIcon name={"close"} color={"#f00"} size={30} />
                            </div>
                        </Stack> */}
                    </Grid>
                </Grid>
            </div>
            <div style={{ paddingTop: 0}}>
            <div style={{ padding: 10 }}>
                <Typography variant='h5'><b>{title}</b></Typography>
                <Typography>{description}</Typography>
            </div>
            {children}
                <Grid container>
                    <Grid item xs={1} md={3} lg={3}></Grid>
                </Grid>
            </div>
        </div>
    )
}
import { ErrorComputer } from "@icon-park/react";
import { Button, Stack, Typography } from "@mui/joy";
import { useNavigate } from "react-router";

export default function Four04() {
  let nav= useNavigate()
  return (
    <div>
      <Stack height={window.innerHeight} justifyContent={"center"} alignItems={"center"}>
        <Stack justifyContent={"center"} alignItems={"center"} spacing={1}>
          <ErrorComputer size={40}/>
          <Typography>Oops! Page Not Found</Typography>
          <div style={{height:100}}></div>
          <Button onClick={()=>{nav('/landing/businesses')}}>Go Home</Button>
        </Stack>
      </Stack>
    </div>
  );
}

import * as React from "react";
import Card from "@mui/joy/Card";
import { Grid, Stack } from "@mui/material";
import KIcon from "../../components/iconifier";

export function DashboardLoader({ data }) {
  switch (data.component) {
    case "Dashboard Card":
      return <DashboardCard title={data.title} value={data.value} />;
    default:
      return <div></div>;
  }
}

export function DashboardCard({ value, title, children }) {
  return (
    <div>
      <Card>
        <Grid container>
          <Grid item xs={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 35,
                backgroundColor: "#f00",
                height: 70,
                width: 70,
              }}
            >
              <div style={{ marginTop: 6 }}>
                <KIcon name={"Home"} color={"#fff"} size={40} strokeWidth={5} />
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              textAlign: "right",
            }}
          >
            <Stack direction={"column"}>
              <span>{title}</span>
              <span style={{ fontSize: 25, fontWeight: "bold" }}>{value}</span>
            </Stack>
          </Grid>
        </Grid>
        <div
          style={{ height: 7, borderRadius: 3.5, backgroundColor: "#f00" }}
        ></div>
        {children}
      </Card>
    </div>
  );
}

import {
  Modal,
  Sheet,
  ModalClose,
  Typography,
  Stack,
  useColorScheme,
  IconButton,
  Button,
} from "@mui/joy";
import * as React from "react";
import { useAPI } from "../controllers/ContextProvider";
import { DarkMode, Light } from "@icon-park/react";

export function KSpan({ children }) {
  return (
    <span style={{ backgroundColor: "#84bcff", padding: 5, borderRadius: 10 }}>
      {children}
    </span>
  );
}
export function UnableToCompleteRequest() {
  let { _alert, setAlert } = useAPI();
  return (
    <div>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={_alert.show}
        onClose={() => setAlert({ show: false, message: "" })}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{ maxWidth: 500, borderRadius: "md", p: 3, boxShadow: "lg" }}
        >
          <div style={{marginBottom:10}}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems={"center"}
            >
              <img src="/static/images/web.png" style={{ height: 100 }} />
              <Typography>{_alert.message}</Typography>

            </Stack>
          </div>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <div></div>
            {/* <Button onClick={setAlert({show:false,message:''})} color="danger">Close</Button> */}
          </Stack>
        </Sheet>
      </Modal>
    </div>
  );
}
export function ColorMode() {
  const { mode, setMode } = useColorScheme();
  return (
    <div>
      <IconButton
        variant="soft"
        color="primary"
        style={{ padding: 10, borderRadius: "50%" }}
        onClick={() => {
          setMode(mode === "light" ? "dark" : "light");
        }}
      >
        <span style={{ paddingLeft: 2, paddingRight: 2 }}>
          {mode === "dark" ? (
            <Light color="#fff" size={20} />
          ) : (
            <DarkMode color="#000" size={20} />
          )}
        </span>
      </IconButton>
    </div>
  );
}

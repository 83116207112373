import axios from "axios";
import React, { createContext, useContext } from "react";
import { Loading } from "../components/loader";
const UserContext = createContext();
export const KContext = ({ children }) => {
  // const APIURL = 'https://forms.hotpot.co.tz/api/'
  // const APIURL = 'http://154.118.227.142:8000/ilmis_back/api/'
  // const host = "http://localhost:8000/";
  // const host = 'http://154.118.227.29/'
  // const host = 'https://management-api.smartccf.or.tz/'
  const host = "https://api.dwkings.co.tz/";
// const host = 'http://192.168.1.110:8000/'
  const APIURL = host + "api/";
  // const APIURL = 'http://154.118.227.29/api/'

  const FILEURL = host + "storage/";
  // const FILEURL = 'http://154.118.227.29/storage/'
  const storageUrl = APIURL;
  const [app, setApp] = React.useState({
    name: "Sokoni",
    desription: "",
    logo: "uploads/logo.png",
    wallpaper: "uploads/wall.png",
    navbar:"sidebar"
  });
  const [loaderStyle, setLoaderStyle] = React.useState("large");
  const [token, setToken] = React.useState(null);
  const [isAuth, setAuth] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [topics, setTopics] = React.useState([]);
  const [showForm, setShowForm] = React.useState(false);
  const [formId, setFormId] = React.useState(0);
  const [_alert,setAlert] =React.useState({show:false,message:"Oops! something went wrong"});
  const [appConfig, setAppConfig] = React.useState({
    sidebar: [],
    formConfig: {},
  });
  const [formBuild, setFormBuild] = React.useState({
    additionalFields: {},
    onSubmitted: () => {},
    onCancel: () => {},
  });
  const [user, setUser] = React.useState({ name: "", role: "" });
  const [additionalFormData, setAdditionalFormData] = React.useState({});
  const [navIsOpen, setNav] = React.useState(true);

  const [business, setBusiness] = React.useState({
    name: "Acsolar Company Limited",
    id: 1,
  });
  const [currentProject, setCurrentProject] = React.useState(1);
  const [formRequestData, setFormRequestData] = React.useState({
    url: "forms/getForm/1",
    formId: 0,
    hasAddon: false,
    addon: "",
    addonType: "",
    addonValue: "",
    callback: () => {},
  });
  const get = (url, loader = { loadingMessage: "Loading" }) => {
    Loading.showLoader(loader.loadingMessage, loaderStyle);
    let _token = token;
    if (_token === null) {
      _token = reloadToken();
    }
    return axios
      .get(APIURL + url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + _token,
        },
      })
      .then((r) => {
        setTimeout(() => {
          Loading.hideLoader(loaderStyle);
        }, 500);
        if(r.data===undefined){
          setAlert({show:true, message:"An unexpected error has occured please try again later"});
          return {data:{},status:false};
        }
        return {data:r.data,status:true};
      })
      .catch((e) => {
        Loading.hideLoader(loaderStyle);
        setAlert({show:true, message:"An unexpected error has occured please try again later"});
        return {data:{},status:false};
        
      });
    // axios.post()
  };
  const loadToken = (toke, user) => {
    sessionStorage.setItem("jwt_token", toke);
    sessionStorage.setItem("user", JSON.stringify(user));
    setUser(user);
    // localStorage.setItem()
  };
  const reloadToken = () => {
    let tok = sessionStorage.getItem("jwt_token");
    let user = JSON.parse(sessionStorage.getItem("user"));
    setUser(user);
    return tok;
  };
  const updateFormRequest = (
    url = 0,
    formId,
    hasAddon = false,
    addonType = "0",
    addonValue = "0",
    callback = () => {}
  ) => {
    setShowForm(true);
    setFormId(0);
    setFormRequestData({
      url: url,
      formId: formId,
      hasAddon: hasAddon,
      addonType: addonType,
      addonValue: addonValue,
      callback: callback,
    });
  };
  const post = (url, data) => {
    let _token = token;
    if (_token === null) {
      _token = reloadToken();
    }
    return axios
      .post(APIURL + url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + _token,
        },
      }).then((r) => {
        setTimeout(() => {
          Loading.hideLoader(loaderStyle);
        }, 500);
        if(r.data===undefined){
          setAlert({show:true, message:"An unexpected error has occured please try again later"});
          return {data:{},status:false};
        }
        return {data:r.data,status:true};
      })
      .catch((e) => {
        Loading.hideLoader(loaderStyle);
        setAlert({show:true, message:"An unexpected error has occured please try again later"});
        return {data:{},status:false};
      });
  };
  const upload = (url, data) => {
    let _token = token;
    if (_token === null) {
      _token = reloadToken();
    }
    return axios
      .post(APIURL + url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + _token,
        },
      }).then((r) => {
        setTimeout(() => {
          Loading.hideLoader(loaderStyle);
        }, 500);
        if(r.data===undefined){
          setAlert({show:true, message:"An unexpected error has occured please try again later"});
          return {data:{},status:false};
        }
        return {data:r.data,status:true};
      })
      .catch((e) => {
        Loading.hideLoader(loaderStyle);
        setAlert({show:true, message:"An unexpected error has occured please try again later"});
        return {data:{},status:false};
      });
  };
  const getFile = (url) => {
    return FILEURL + url;
  };
  const getFormConfig = (name) => {
    return appConfig.formConfig[name];
  };
  const buildForm = (
    formId,
    additionalFields = {},
    onSubmitted = () => {},
    onCancel = () => {}
  ) => {
    if (formId !== 0) {
      get("forms/getForm/" + formId).then((r) => {
        setFormId(formId);
        setFormBuild({
          form: r.data,
          onCancel: onCancel,
          additionalFields: additionalFields,
          onSubmitted: onSubmitted,
        });
      });
    }
    setShowForm(true);
  };
  return (
    <UserContext.Provider
      value={{
        _alert,
        setAlert,
        app,
        navIsOpen,
        setNav,
        setApp,
        currentProject,
        getFormConfig,
        getFile,
        updateFormRequest,
        formRequestData,
        setCurrentProject,
        business,
        setBusiness,
        additionalFormData,
        setAdditionalFormData,
        formId,
        setFormId,
        showForm,
        setShowForm,
        upload,
        topics,
        setTopics,
        token,
        isAuth,
        user,
        post,
        get,
        setAuth,
        setToken,
        setUser,
        setLoading,
        loading,
        appConfig,
        setAppConfig,
        storageUrl,
        APIURL,
        loaderStyle,
        loadToken,
        setLoaderStyle,
        buildForm,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const useAPI = () => {
  return useContext(UserContext);
};

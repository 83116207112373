import * as React from "react";
import { useAPI } from "../controllers/ContextProvider";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ButtonAppBar } from "../components/hb";
import { FullScreenNav, KNavigation, openNav } from "../components/sidebar";
import { FormBuilder } from "../panels/forms/formbuilder";
import { KLoader, Loading } from "../components/loader";
import { KUtility } from "../components/utility";
export default function ManagementWindow(props) {
  let {
    get,
    appConfig,
    setAppConfig,
    setLoaderStyle,
    loaderStyle,
  } = useAPI();
  let nav = useNavigate();
  let loc = useLocation();
  const [navs, snavs] = React.useState(
    appConfig !== null ? appConfig.sidebar : []
  );
  React.useEffect(() => {
    if(KUtility.isMobile()){
      document.getElementById("content").style.flexBasis="100%"
    }
    openNav()
    get("appconfig/get/app/1")
      .then((r) => {
        setAppConfig(r.data);
        let p = r.data;
        snavs(p.sidebar);
        if (
          p.sidebar.length > 0 &&
          loc.pathname.includes("/forms") &&
          loc.pathname.length <= 7
        ) {
          nav(p.sidebar[0].link, { replace: true });
        }
      })
      .then((r) => {
        Loading.hideLoader("large", loaderStyle);
        setLoaderStyle("window");
      });
  }, []);
  return (
    <div>
      <KNavigation nav={nav} navs={navs} type="sidebar">
        <ButtonAppBar />
        <div
          style={{
            overflowX: "hidden",
            height: window.innerHeight - 82,
            padding: 10,
          }}
        >
          <KLoader size="window" />
          <FormBuilder />
          <Outlet />
        </div>
      </KNavigation>
    </div>
  );
}

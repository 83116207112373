import * as React from "react";
import {Grid} from "@mui/material";
import {useLocation} from "react-router";
import {useAPI} from "../../controllers/ContextProvider";
import {Button, Card} from "@mui/joy";
import {GetPreview} from "../../components/inputpreview";
import KIcon from "../../components/iconifier";
import KTable from "../../components/table";
import {KSpan} from "../../components/components";

export default function APIPreview(){
    let data = useLocation().state.data;
    let form = useLocation().state.form;
    const [heads,setHeads] = React.useState([{
        field:"id",
        headerName:"ID"
    }]);
    React.useEffect(()=>{
        let p = [];
        form.api_fields.response_collection.forEach(prop=>{
            p.push({field:prop.path, headerName: prop.name});
        });
        setHeads(p);
    },[]);
    let {get,post,setFormId,setShowForm,setAdditionalFormData} = useAPI();
    const callAPI = ()=>{
        get(`thirdparty/apiRequest/form/${form.id}/${data.tag}`).then(r=>{

        })
    }
    return(
        <Card>
            <Grid spacing={3} container>
                <Grid item xs={12} md={12} lg={12}>
                    <h2>{form.name}</h2>
                        <span></span><KSpan >Endpoint : {form.api_fields.endpoint}</KSpan>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <h2>Query</h2>
                    <KSpan>
                        {JSON.stringify(form.api_fields.parameters)}
                    </KSpan>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <h2>Parameters</h2>
                </Grid>

                {
                    data.contents.map((prop,key)=>(
                        <Grid item xs={12} sm={4} md={3}>
                            <Card style={{backgroundColor:"#e4e4e4"}}>
                                <span>{prop.rform.name}</span>
                                <GetPreview name={prop.rform.name} data={prop.objectresponse} type={prop.rform.type.name}/>
                            </Card>
                        </Grid>
                    ))
                }
                <Grid item xs={12} md={12} lg={12}>
                    <Button onClick={callAPI} color={"primary"} startDecorator={<KIcon color={"#fff"} name={"play-one"} strokeWidth={4} />}>Run API Query</Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <KTable rows={[]} minHeight={window.innerHeight/1.7} columns={heads}/>
                </Grid>
            </Grid>
        </Card>
    )
}
import * as React from "react";
import { useAPI } from "../../controllers/ContextProvider";
import FormPane from "./form_pane";
import { FloatingLabelInput, LocalitySelector } from "../../components/input";
import {
  Button,
  Checkbox,
  FormLabel,
  Radio,
  RadioGroup,
  Textarea,
  Typography,
  ButtonGroup,
  Modal,
  Sheet,
  ModalClose,
  useTheme,
  useColorScheme,
  Input,
} from "@mui/joy";
import { Grid, Stack } from "@mui/joy";
import { Dropzone, FileMosaic } from "@files-ui/react";
import Select from "react-select";
import { hideDialog, showDialog } from "../../components/dialog";
import { Loading } from "../../components/loader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DoubleLeft, DoubleRight, Left, Right } from "@icon-park/react";

export function FormBuilderView({ preform }) {
  const [form, setForm] = React.useState({
    fields: [],
    topic: { name: "", description: "", code: "" },
  });
  let { formId, get, setShowForm, formRequestData } = useAPI();

  React.useEffect(() => {
    if (preform === undefined) {
      if (formId > 0) {
        get(`forms/getForm/${formId}`).then((r) => {
          if (r.status) {
            setForm(r.data);
          }
          // setAdditionalFormData({});
        });
      } else if (formRequestData.formId > 0) {
        get(
          `forms/getForm/${formRequestData.formId}/byObject/${formRequestData.addonValue}/${formRequestData.addonType}`
        ).then((r) => {
          if (r.status) {
            setForm(r.data);
          }
        });
      }
    } else {
      setForm(preform);
    }
  }, [formId, formRequestData]);

  return (
    <div>
      <FormPane
        title={form.topic.name}
        description={form.topic.description}
        onClose={() => {
          setShowForm(false);
        }}
      >
        <KForm form={form} />
      </FormPane>
    </div>
  );
}

export function KForm({ form, additionalData = null }) {
  const [formData, setFormData] = React.useState({});
  const [files, setFiles] = React.useState({});
  const [, forceUpdate] = React.useState();
  let { upload, additionalFormData, setShowForm, onFormSubmit, loaderStyle } =
    useAPI();
  const [additional, setAdditional] = React.useState(
    additionalData !== null ? additionalData : additionalFormData
  );
  const onChange = (e, v) => {
    let f = formData;
    f[e] = v;
    setFormData(f);
    forceUpdate({});
  };
  const onFileChange = (e, v) => {
    let f = files;
    f[e] = v;
    setFiles(f);
    forceUpdate({});
  };
  const submit = () => {
    const forsm = document.forms.fosrm;
    let fd = new FormData(forsm);
    Loading.showLoader("Submitting", loaderStyle);
    Object.keys(additional).map((field) => {
      fd.append(field, additional[field]);
    });
    fd.append("filenames", JSON.stringify(form.filenames));
    fd.append("topic", form.topic.id);
    fd.append("textData", JSON.stringify(formData));
    fd.append("code", form.topic.code);
    form.filenames.forEach((e) => {
      files[e].forEach((file) => {
        fd.append(e, file.file, e);
      });
    });
    upload("forms/add/responses", fd).then((r) => {
      Loading.hideLoader(loaderStyle);
      showDialog();
      setShowForm(false);

      setTimeout(() => {
        hideDialog();
      }, 3000);
    });
  };
  return (
    <form
      id={"fosrm"}
      encType={"multipart/form-data"}
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      {form.fields.map((prop, key) => (
        <div>
          {prop.map((field, key) => (
            <div item xs={12}>
              {field.parent ? (
                formData["g" + field.parentElement] !== field.parentValue ||
                (additional.parent !== undefined && !field.showOnChild) ||
                (additional.parent === undefined &&
                  !field.showOnParent) ? null : (
                  <GetInput
                    multiple={field.multiple}
                    onChange={onChange}
                    onFileChange={onFileChange}
                    input={field}
                    type={field.type.name}
                  />
                )
              ) : (additional.parent !== undefined && !field.showOnChild) ||
                (additional.parent === undefined &&
                  !field.showOnParent) ? null : (
                <GetInput
                  multiple={field.multiple}
                  onChange={onChange}
                  onFileChange={onFileChange}
                  input={field}
                  type={field.type.name}
                />
              )}
            </div>
          ))}
        </div>
      ))}
      <div style={{ marginTop: 20 }}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Button type="submit">Continue</Button>
        </Stack>
      </div>
    </form>
  );
}

function TheInput({ type, input, onChange, multiple = "0", onFileChange }) {
  if (multiple === "1") {
    input.multiple = multiple === "1";
    input["mn"] = "[]";
  } else {
    input["mn"] = "";
  }
  input["iname"] = "g" + input.id + input.mn;
  switch (type) {
    case "ButtonGroup":
      return <ButtonGroupInput e={input} onChange={onChange} />;
    case "textarea":
      return <KTextArea e={input} onChange={onChange} />;
    case "text":
      return <TextInput e={input} onChange={onChange} />;
    case "dateRange":
      return <DateRangePicker e={input} onChange={onChange} />;
    // return <TextInput e={input} onChange={onChange}/>;
    case "date":
      return <TextInput e={input} onChange={onChange} />;
    case "number":
      return <TextInput e={input} onChange={onChange} />;
    case "tabularinput":
      return <TabularInput e={input} onChange={onChange} />;
    case "select":
      return <DSelect e={input} onChange={onChange} />;
    case "DatabaseSelect":
      return <DSelect e={input} onChange={onChange} />;
    case "LiveSelect":
      return <DSelect e={input} onChange={onChange} live={true} />;
    case "checkbox":
      return <KCheckbox e={input} onChange={onChange} />;
    case "radio":
      return <KRadio e={input} onChange={onChange} />;
    case "file":
      return <FileUpload e={input} onChange={onFileChange} />;
    case "Pair":
      return (
        <PairInput e={input} onFileChange={onFileChange} onChange={onChange} />
      );
    case "YesNo":
      return <YesNo e={input} onChange={onChange} />;
    case "time":
      return <TextInput e={input} onChange={onChange} />;
    case "image":
      return <FileUpload onChange={onFileChange} e={input} />;
    case "Two sided select":
      return <TwoSidedSelect data={input} onChange={onChange} />;
    case "region":
    case "district":
    case "ward":
    case "street":
      return <LocalitySelector e={input} type={type} onChange={onChange} />;
    default:
      return null;
  }
}

export function FormBuilder() {
  let { showForm, setShowForm } = useAPI();
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      size="lg"
      open={showForm}
      onClose={() => setShowForm(false)}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Sheet
        variant="outlined"
        sx={{ maxWidth: 500, borderRadius: "md", p: 3, boxShadow: "lg" }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <FormBuilderView />
      </Sheet>
    </Modal>
  );
  return showForm ? <FormBuilderView /> : null;
}

export function GetInput({
  type,
  input,
  onChange,
  onFileChange,
  multiple = "0",
}) {
  const [p, setP] = React.useState([0]);
  const [, forceUpdate] = React.useState();
  const addField = () => {
    let po = p;
    po.push(0);
    setP(po);
    forceUpdate({});
  };
  React.useEffect(() => {}, []);
  return (
    <Grid container>
      {p.map((pp) => (
        <Grid item xs={12}>
          <TheInput
            onFileChange={onFileChange}
            type={type}
            input={input}
            onChange={onChange}
            multiple={multiple}
          />
        </Grid>
      ))}
      <Grid item xs={3}>
        {!type.toLowerCase().includes("select") && multiple === "1" ? (
          <Button style={{ marginBottom: 5 }} onClick={addField}>
            Add field
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
}

function YesNo({ e, onChange }) {
  return (
    <div>
      <FormLabel>{e.name}</FormLabel>
      <RadioGroup
        name={e.iname}
        onChange={(v) => {
          onChange("g" + e.id + e.mn, v.target.value);
        }}
      >
        <Radio value={"Yes"} label={"Yes"} />
        <Radio value={"No"} label={"No"} />
      </RadioGroup>
    </div>
  );
}

function KRadio({ e, onChange }) {
  return (
    <div>
      <FormLabel>{e.name}</FormLabel>
      <RadioGroup
        name={e.iname}
        onChange={(v) => {
          onChange("g" + e.id + e.mn, v.target.value);
        }}
      >
        {e.options.map((prop, key) => (
          <Radio value={prop.value} label={prop.name} />
        ))}
      </RadioGroup>
    </div>
  );
}

function TextInput({ e, onChange }) {
  return (
    <FloatingLabelInput
      label={e.name}
      type={e.type.name}
      onChange={(ee) => {
        onChange(e.iname, ee.target.value);
      }}
      min={0}
      required={e.required === "1"}
      name={"g" + e.id + e.mn}
    />
  );
}

function KCheckbox({ e, onChange }) {
  React.useEffect(() => {
    onChange(e.iname, "0");
  }, []);
  return (
    <div>
      <Stack direction={"row"}>
        <Checkbox
          name={e.iname}
          label={e.name}
          value={"1"}
          onChange={(ev) => {
            onChange("g" + e.id + e.mn, ev.target.checked ? "1" : "0");
          }}
        />
      </Stack>
    </div>
  );
}

export function DSelect({ e, onChange, live = false, defaultValue = "" }) {
  const [, forceupdate] = React.useState();
  const [options, setOptions] = React.useState(e.options);
  let { get } = useAPI();
  const optionfy = (eu) => {
    let g = [];
    eu.forEach((prop) => {
      let val = prop.value;
      if (val === null || val === undefined) {
        val = prop.id;
      }
      g.push({ label: prop.name, value: val });
    });
    return g;
  };
  const theme = useTheme();
  let { mode } = useColorScheme();
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: theme.colorSchemes[mode].palette.background.level1,
      borderColor: theme.colorSchemes[mode].palette.neutral.outlinedBorder,
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 5,
      marginLeft: 0,
      boxShadow: state.isFocused ? null : null,
      zIndex: 1,
    }),
    container:(provided,state)=>({
      ...provided,
      width:"100%",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      background: theme.colorSchemes[mode].palette.background.level1,
      // height: '30px',
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2,
      background: theme.colorSchemes[mode].palette.background.level1,
    }),
    menuList: (provided, state) => ({
      ...provided,
      zIndex: 2,
      background: theme.colorSchemes[mode].palette.background.level1,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? theme.colorSchemes[mode].palette.primary[500]
          : isFocused
          ? theme.colorSchemes[mode].palette.primary.softBg
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? theme.colorSchemes[mode].palette.text.primary
          : theme.colorSchemes[mode].palette.text.primary,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? theme.colorSchemes.dark.palette.primary[500]
              : theme.colorSchemes.dark.palette.primary.softBg
            : undefined,
        },
      };
    },
  };

  const getLive = () => {
    setTimeout(() => {
      if (live) {
        document
          .getElementById("g" + e.c_rules.parent_element)
          .addEventListener("change", (ev) => {
            let p = document.getElementById(
              "g" + e.c_rules.parent_element
            ).value;
            get(
              `forms/input/liveSelect/${e.c_rules.db}/${e.c_rules.field}/${p}`
            ).then((r) => {
              if (r.status) {
                setOptions(optionfy(r.data));
              }
            });
          });
      }
    }, 3000);
  };
  React.useEffect(() => {
    getLive();
    forceupdate({});
  }, []);
  return (
    <div>
      <div>
        <Select
          id={"g" + e.id}
          name={e.iname}
          styles={customStyles}
          placeholder={e.name}
          minMenuHeight={20}
          options={optionfy(e.options)}
          isMulti={e.multiple === "1" || e.multiple === true}
          onChange={(eb, v) => {
            if (Array.isArray(eb)) {
              let p = [];
              eb.forEach((t) => {
                p.push(t.value);
              });
              onChange("g" + e.id, p);
            } else {
              onChange("g" + e.id + e.mn, eb.value);
            }
          }}
        />
      </div>
    </div>
    // <KSelect options={e.options} valueField={"id"} name={e.name} onChange={(ev)=>{alert(ev.target.value);onChange('g'+e.id,ev.target.value)}}/>
  );
}

function KTextArea({ e, onChange }) {
  return (
    <Textarea
      name={e.iname}
      placeholder={e.name}
      minRows={4}
      onChange={(eb) => {
        onChange("g" + e.id + e.mn, eb.target.value);
      }}
      required={e.required}
    />
  );
}

function TabularInput() {}

function FileUpload({ e, onChange }) {
  const [files, setFiles] = React.useState([]);
  const removeFile = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };
  React.useEffect(() => {}, [files]);
  const updateFiles = (incoming) => {
    setFiles(incoming);
    onChange("g" + e.id, incoming);
  };
  return (
    <div>
      <FormLabel>{e.name}</FormLabel>
      <Dropzone
        accept={e.c_rules.accept}
        onChange={updateFiles}
        value={files}
        //accept="image/*"
      >
        {files.map((file) => (
          <FileMosaic key={file.id} {...file} onDelete={removeFile} info />
        ))}
      </Dropzone>
    </div>
  );
}

function PairInput({ e, onChange, onFileChange }) {
  const [pairs, setPairs] = React.useState([1]);
  const [, forceUpdate] = React.useState();
  const [data, setData] = React.useState([]);
  const addField = () => {
    let p = pairs;
    p.push(1);
    setPairs(p);
    forceUpdate({});
  };
  const onPairChange = (i, ie, v, prop) => {
    let p = data;
    if (p.length - 1 >= i) {
    } else {
      p.push({});
    }
    p[i]["g" + prop] = v;
    setData(p);
    onChange("g" + e.id, p);
  };
  return (
    <div>
      <p>{e.name}</p>
      {pairs.map((prop, key) => (
        <div>
          <Stack direction={"row"} container spacing={1}>
            {e.pairs.map((pp, ky) => (
              <Grid item xs={6}>
                <GetInput
                  onFileChange={onFileChange}
                  type={pp.type.name}
                  multiple={"0"}
                  input={pp}
                  onChange={(ev, v) => {
                    onPairChange(key, ev, v, pp.id);
                  }}
                />
              </Grid>
            ))}
          </Stack>
        </div>
      ))}
      <Button
        style={{ marginBottom: 5 }}
        type="button"
        variant={"outlined"}
        onClick={addField}
      >
        Add Field
      </Button>
    </div>
  );
}

function DateRangePicker({ e, onChange }) {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (date && endDate < date) {
      setEndDate(date);
      onChange("g" + e.id, startDate + "-" + endDate);
    }
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    onChange("g" + e.id, startDate + "-" + endDate);
  };
  const today = new Date();
  return (
    <div>
      <p>{e.name}</p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 4,
        }}
      >
        <DatePicker
          slotProps={{ field: { sx: { style: { width: "100%" } } } }}
          // loading={true}
          label="Start date"
          value={startDate}
          maxDate={dayjs(today)}
          onChange={handleStartDateChange}
        />
        <p>TO</p>
        <DatePicker
          label="End date"
          value={endDate}
          minDate={startDate}
          maxDate={dayjs(today)}
          onChange={handleEndDateChange}
        />
      </div>
    </div>
  );
}

function ButtonGroupInput({ e, onChange }) {
  return (
    <div>
      <Typography>{e.name}</Typography>
      <ButtonGroup>
        {e.options.map((prop, key) => (
          <Button
            key={key}
            onClick={() => {
              onChange(e.id, [prop.value]);
            }}
          >
            {prop.name}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}

function TwoSidedSelect({ data, onChange = (name, value) => {} }) {
  const optionfy = (e) => {
    let g = [];
    e.forEach((prop) => {
      g.push({ label: prop.name, value: prop.value });
    });
    return g;
  };
  const [opts, setOpts] = React.useState([]);
  const [optOut, setOptOut] = React.useState([]);
  const [selectedOptions1, setSelectedOptions1] = React.useState([]);
  const [selectedOptions2, setSelectedOptions2] = React.useState([]);
  const [, forceUpdate] = React.useState();

  const getItem = (value) => {
    opts.forEach((t) => {
      if (t.value == value) {
        return t;
      } else {
      }
    });
    return null;
  };
  const selectOption = (val) => {
    let se = selectedOptions1;
    if (!selectedOptions1.includes(val)) {
      se.push(val);
    } else {
      se.splice(se.indexOf(val), 1);
    }
    setSelectedOptions1(se);
    forceUpdate({});
  };
  const unSelectOption = (val) => {
    let se = optOut;
    if (!optOut.includes(val)) {
      se.push(val);
    } else {
      se.splice(se.indexOf(val), 1);
    }
    setOptOut(se);
    forceUpdate({});
  };
  const addItem = (items) => {
    let p = [...selectedOptions2, ...selectedOptions1];
    let r = opts;
    // p.concat(r);
    setSelectedOptions2(p);
    selectedOptions1.forEach((item) => {
      r.splice(r.indexOf(item), 1);
      // if (r.includes(item)) {
      // }
    });
    setOpts(r);
    setSelectedOptions1([]);
    whenChanges();
    forceUpdate({});
  };
  const removeItem = () => {
    let p = [...opts, ...optOut];
    let r = selectedOptions2;
    setOpts(p);
    optOut.forEach((item) => {
      r.splice(r.indexOf(item), 1);
    });
    setSelectedOptions2(r);
    setOptOut([]);
    whenChanges();
    forceUpdate({});
  };
  const removeAll = () => {
    setOpts(optionfy(data.options));
    setOptOut([]);
    setSelectedOptions2([]);
    whenChanges();
    forceUpdate({});
  };
  const addAll = () => {
    setOpts([]);
    setSelectedOptions1([]);
    setSelectedOptions2(optionfy(data.options));
    whenChanges();
    forceUpdate({});
  };
  const whenChanges = () => {
    let p = [];
    selectedOptions2.forEach((t) => {
      p.push(t.value);
    });
    onChange("g" + data.id, p);
  };
  React.useEffect(() => {
    setOpts(optionfy(data.options));
    forceUpdate({});
  }, [data.options]);
  return (
    <div>
      <Typography>
        <b>{data.name}</b>
      </Typography>
      <Grid container alignItems={"center"} spacing={1}>
        <Grid item xs={5}>
          <Stack spacing={1} maxHeight={500}>
            {opts.map((prop, key) => (
              <Button
                variant={selectedOptions1.includes(prop) ? "solid" : "soft"}
                onClick={() => {
                  selectOption(prop);
                }}
                color={"neutral"}
              >
                {prop.label}
              </Button>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack spacing={2}>
            <Stack justifyContent={"center"} direction={"row"}>
              <Button color="neutral" onClick={removeAll} variant="soft">
                <DoubleLeft size={20} />
              </Button>
            </Stack>
            <Stack justifyContent={"center"} direction={"row"}>
              <Button color="neutral" onClick={removeItem} variant="soft">
                <Left size={20} />
              </Button>
            </Stack>
            <Stack justifyContent={"center"} direction={"row"}>
              <Button color="neutral" onClick={addItem} variant="soft">
                <Right size={20} />
              </Button>
            </Stack>
            <Stack justifyContent={"center"} direction={"row"}>
              <Button onClick={addAll} color="neutral" variant="soft">
                <DoubleRight size={20} />
              </Button>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Stack spacing={1} maxHeight={500} overflow={"auto"}>
            {selectedOptions2.map((prop, key) => (
              <Button
                variant={optOut.includes(prop) ? "solid" : "soft"}
                onClick={() => {
                  unSelectOption(prop);
                }}
                color={"neutral"}
              >
                {prop.label}
              </Button>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}

function AutoCalc({data,info, onChange}){
  const mathFunx = ()=> {
    switch(data.c_rules.AutoCalc){
      case "sum":
    }
  }
  return(
    <Input readOnly value={mathFunx()} />
  )
}
import {
  CardContent,
  Card,
  Stack,
  Typography,
  TextField,
  Button,
  Sheet,
  useTheme,
  useColorScheme,
} from "@mui/joy";
import * as React from "react";
import { FloatingLabelInput } from "../components/input";
import { Login } from "@icon-park/react";
import { useAPI } from "../controllers/ContextProvider";
import { useNavigate } from "react-router";
import { Grid } from "@mui/material";
import { Loading } from "../components/loader";
import ParticleContainer from "../components/particle";
import { KUtility } from "../components/utility";
import { ColorMode } from "../components/components";
export default function CompactLogin() {
  const [email, setEmail] = React.useState(null);
  const [pwd, setPwd] = React.useState(null);
  const [error, setError] = React.useState("");
  let { get, post, setToken, app, getFile, loadToken } = useAPI();
  let { mode } = useColorScheme();
  let nav = useNavigate();
  let bg = mode === "light" ? "#eeeeee" : "#000";
  const onsubmit = () => {
    Loading.showLoader("Authenticating");
    post("auth/login", {
      email: email,
      password: pwd,
    })
      .then((r) => {
        if (r.data.authorization.token) {
          setToken(r.data.authorization.token);
          loadToken(r.data.authorization.token, r.data.user);
          nav("/forms");
        } else {
          setError("Wrong username or password");
        }
        Loading.hideLoader();
      })
      .catch((e) => {
        setError("Wrong username or password");
        Loading.hideLoader();
      });
  };
  return (
    <div>
      <ParticleContainer />
      <Grid
        spacing={1}
        style={{ userSelect: "none" }}
        container
        className="animate__animated"
        alignItems={"center"}
      >
        <Grid item xs={12} md={1} lg={1}></Grid>
        <Grid item xs={12} md={6} lg={6}>
          {!KUtility.isMobile() ? (
            <img
              className="animate__animated animate__fadeInLeft"
              style={{ height: window.innerHeight / 3 }}
              src={getFile("uploads/appman.png")}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Stack
            style={{
              height: window.innerHeight,
              justifyContent: "center",
              // alignItems:"center",
            }}
          >
            <Sheet
              style={{
                padding: 10,
                backdropFilter: "blur(5px)",
                backgroundColor: "#555e6812",
                borderRadius: 20,
              }}
            >
              <form
                action="apk.js"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <center className="animate__animated animate__fadeInDown">
                  <ColorMode />
                  <div style={{ height: 10 }}></div>
                  <Typography
                    style={{ marginBottom: 40 }}
                    level="h1"
                    fontWeight={"bold"}
                  >
                    Welcome Back
                  </Typography>
                  <img
                    src={getFile(app.logo)}
                    style={{ height: 150, width: "auto", borderRadius: 100 }}
                  />
                  <Typography fontWeight={"bold"} variant="h5">
                    {app.name}
                  </Typography>
                  <div style={{ height: 40 }}></div>
                  <Typography
                    variant="body1"
                    color="#f00"
                    style={{ color: "#f00" }}
                    fontWeight={"bold"}
                  >
                    {error}
                  </Typography>
                </center>
                <div className="animate__animated animate__fadeInUp">
                  <Stack padding={2} direction={"column"} spacing={2}>
                    <FloatingLabelInput
                      required={true}
                      name="email"
                      label={"Email Address"}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      type={"email"}
                    />

                    <FloatingLabelInput
                      required={true}
                      name="password"
                      label={"Password"}
                      onChange={(e) => {
                        setPwd(e.target.value);
                      }}
                      type={"password"}
                    />
                    <Stack
                      justifyContent={"space-between"}
                      direction={"row"}
                      alignItems={"center"}
                    >
                      <Typography
                        href="#"
                        onClick={() => {
                          nav("/register");
                        }}
                      >
                        <b>Not yet a member?</b>
                      </Typography>
                      <Button
                        type="submit"
                        style={{ float: "right" }}
                        onClick={onsubmit}
                        title="Sign Up"
                        endDecorator={<Login />}
                        variant="solid"
                      >
                        Sign In
                      </Button>
                    </Stack>
                  </Stack>
                </div>
                <div style={{ height: 10 }}></div>
              </form>
            </Sheet>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}

import * as React from "react";
import { Button, Grid, useColorScheme, useTheme } from "@mui/joy";
import Card from "@mui/joy/Card";
import KIcon from "./iconifier";
import { useAPI } from "../controllers/ContextProvider";
import { Stack, Typography } from "@mui/joy";
import { useNavigate } from "react-router";
import { KUtility } from "./utility";
import { IconButton } from "@mui/joy";
import { Close } from "@icon-park/react";

export function openNav(type) {
  let doc = document.getElementsByClassName("menu");
  let drw = document.getElementById("drawer");
  let con = document.getElementById("content");
  let sid = document.getElementById("app-sidebar");
  if (KUtility.isMobile()) {
    let p = sid.style.display;
    sid.style.display = p === "block" ? "none" : "block";
    con.style.maxWidth = "100%";
  } else if (type !== "fullscreen") {
    drw.style.maxWidth = "16%";
    con.style.maxWidth = "84%";
  } else {
    setTimeout(() => {
      con.style.maxWidth = "100%";
      let container = document.getElementById("menucont");
      container.style.display = "block";
      container.style.opacity = 1;
    }, 700);
  }
  for (let i = 0; i < doc.length; i++) {
    setTimeout(() => {
      doc[i].classList.remove("menu-hidden");
    }, (i + 1) * 70);
  }
}
export function closeNav(type) {
  let drw = document.getElementById("drawer");
  let con = document.getElementById("content");
  let sid = document.getElementById("app-sidebar");
  if (KUtility.isMobile()) {
    let p = sid.style.display;
    sid.style.display = p === "block" ? "none" : "block";
    con.style.maxWidth = "100%";
  } else if (type !== "fullscreen") {
    drw.style.maxWidth = "5%";
    con.style.maxWidth = "95%";
  } else {
    setTimeout(() => {
      let container = document.getElementById("menucont");
      container.style.display = "none";
      container.style.opacity = 0;
    }, 700);
  }
  let doc = document.getElementsByClassName("menu");
  for (let i = 0; i < doc.length; i++) {
    setTimeout(() => {
      doc[i].classList.add("menu-hidden");
    }, (i + 1) * 100);
  }
}
export function FullScreenNav({ navs, nav }) {
  const navigateNav = (prop) => {
    closeNav("fullscreen");
    nav(prop.link);
  };
  let { get, app } = useAPI();
  React.useEffect(() => {
    let p = document.getElementById("content");
    p.style.flexBasis = "100%";
    p.style.maxWidth = "100%";
  }, []);
  return (
    <div id={"menucont"} className={"animate__animated bounceIn"}>
      <div
        style={{
          backgroundColor: "rgba(130,191,206,0.7)",
          zIndex: 10,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: 0,
          backdropFilter: "blur(30px)",
        }}
      >
        <Grid container style={{ backgroundColor: "#1065A1" }}>
          <Grid item xs={10} style={{ paddingLeft: 40, color: "#fff" }}>
            <h1>Menu</h1>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <div
              onClick={() => {
                closeNav("fullscreen");
              }}
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingRight: 10,
              }}
            >
              <KIcon name={"close"} size={30} color={"#fff"} />
            </div>
          </Grid>
        </Grid>
        <div style={{ marginLeft: 0, marginRight: 0 }}>
          <Grid container>
            <Grid xs={3} md={3} xl={3}>
              <div
                style={{
                  height: window.innerHeight,
                  backgroundColor: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                }}
              >
                <img src={"/static/brands/gov.png"} style={{ width: "50%" }} />
                <div>
                  <h3 style={{ textTransform: "uppercase" }}>{app.name}</h3>
                  <h5>Monitoring & Evaluation Database</h5>
                </div>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                      display: "flex",
                    }}
                  >
                    <SideIcon icon={"user"} />
                    <SideIcon icon={"setting"} />
                    <SideIcon icon={"logout"} />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid
              xs={9}
              md={9}
              xl={9}
              style={{ height: window.innerHeight - 90, overflowY: "scroll" }}
            >
              <Grid container>
                {navs.map((prop) => (
                  <Grid
                    className={"animate__animated bounceIn"}
                    onClick={() => {
                      navigateNav(prop);
                    }}
                    item
                    xs={3}
                    md={3}
                  >
                    <div
                      className={"menu"}
                      style={{
                        textAlign: "center",
                        border: ".1px solid rgba(255,255,255,0.1)",
                      }}
                    >
                      <div style={{ paddingTop: 20 }}>
                        <KIcon name={prop.icon} size={120} />
                      </div>
                      <div
                        style={{
                          height: 100,
                          borderBottomRightRadius: 20,
                          borderBottomLeftRadius: 20,
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <h3
                          style={{
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          {prop.name}
                        </h3>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
function SideIcon({ icon }) {
  return (
    <div className={"icon-dt"}>
      <KIcon size={22} strokeWidth={4} name={icon} />
    </div>
  );
}
function SideBar({ navs, nav, sizes }) {
  let { getFile, app } = useAPI();
  const [active, setActive] = React.useState(0);
  const [, forceUpdate] = React.useState();
  let theme = useTheme();
  let { mode } = useColorScheme();
  return (
    <div
      style={{
        position: KUtility.isMobile() ? "fixed" : "static",
        top: 0,
        left: 0,
        zIndex: 2,
      }}
    >
      <Card
        id="app-sidebar"
        style={{
          borderRadius: 0,
          backgroundColor:
            theme.colorSchemes[mode].palette.primary.outlinedHoverBg,
          padding: 0,
          width: "100%",
          overflow: "overlay",
          height: window.innerHeight,
          display: KUtility.isMobile() ? "none" : "block",
        }}
        className={"animate__animated animate__fadeInLeft noprint"}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
            backgroundColor:
              theme.colorSchemes[mode].palette.primary.outlinedHoverBg,
            zIndex: 2,
          }}
        >
          <IconButton onClick={()=>{closeNav("sidebar")}} style={{ float: "right" }}>
            <Close color="#fff" />
          </IconButton>
          <center>
            <img
              src={getFile(app.logo)}
              style={{
                width: KUtility.isMobile() ? "20%" : "50%",
                height: "auto",
                borderRadius: 20,
                marginTop: 30,
                marginBottom: 30,
              }}
            />
            <h5 style={{ textTransform: "uppercase" }}>{app.name}</h5>
          </center>
        </div>
        {navs.map((prop, key) => (
          <MenuItem
            icon={prop.icon}
            id={key}
            active={active}
            setActive={() => {
              setActive(key);
              forceUpdate({});
            }}
            name={prop.name}
            link={prop.link}
          />
        ))}
      </Card>
    </div>
  );
}
export function KNavigation({ type = "fullscreen", navs = [], nav, children }) {
  const sizes = {
    fullscreen: {
      menu: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
      container: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
    sidebar: {
      menu: {
        xs: 12,
        sm: 6,
        md: 2,
        lg: 2,
        xl: 2,
      },
      container: {
        xs: 12,
        sm: 6,
        md: 10,
        lg: 10,
        xl: 10,
      },
    },
  };
  return (
    <div>
      <Grid container>
        <Grid
          item
          id="drawer"
          xs={sizes[type].menu.xs}
          md={sizes[type].menu.md}
          sm={sizes[type].menu.sm}
          lg={sizes[type].menu.lg}
          xl={sizes[type].menu.xl}
        >
          {type === "fullscreen" ? (
            <FullScreenNav navs={navs} nav={nav} />
          ) : (
            <SideBar sizes={sizes.sidebar.menu} navs={navs} nav={nav} />
          )}
        </Grid>
        <Grid
          id="content"
          
          xs={sizes[type].container.xs}
          md={sizes[type].container.md}
          sm={sizes[type].container.sm}
          lg={sizes[type].container.lg}
          xl={sizes[type].container.xl}
        >
          {children}
        </Grid>
      </Grid>
    </div>
  );
}
function MenuItem({ name, link, icon, setActive = () => {}, active, id }) {
  let nav = useNavigate();
  let theme = useTheme();
  let { mode } = useColorScheme();
  let { navIsOpen } = useAPI();
  return (
    <Stack spacing={2}>
      <Button
        onClick={() => {
          nav(`${link}`);
          setActive();
          hideSidebarMobile();
        }}
        color="primary"
        variant={active === id ? "solid" : "soft"}
        style={{
          padding: 15,
          marginBottom: 5,
          justifyContent: navIsOpen ? "flex-start" : "center",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: 30,
          borderBottomRightRadius: 30,
          marginRight: 10,
        }}
        className={
          active === id ? "animate__animated animate__fadeInLeft krnav" : ""
        }
      >
        <Stack
          direction="row"
          justifyContent={navIsOpen || KUtility.isMobile() ? "left" : "center"}
          spacing={2}
          alignItems={"center"}
        >
          <KIcon
            // color={theme.colorSchemes[mode].palette.text.primary}
            size={23}
            strokeWidth={4}
            name={icon}
          />
          {navIsOpen || KUtility.isMobile() ? <span>{name}</span> : null}
        </Stack>
      </Button>
    </Stack>
  );
}
function hideSidebarMobile() {
  if (KUtility.isMobile()) {
    document.getElementById("app-sidebar").style.display = "none";
  }
}

import {Alert, Button, Card, Chip, Table, Typography} from '@mui/joy'
import { Grid, Stack, TableCell, TableHead, TableRow } from '@mui/material'
import * as React from 'react'
import { useAPI } from '../controllers/ContextProvider'
import KMap from './map'
import { Marker, Popup } from 'react-leaflet'
import KIcon from "./iconifier";
import ResponseTabularView from '../panels/responsetabular'
function TextData({ data }) {
    return (<div>
        {/* <TabularInput dat={[]} /> */}
        {data.map((prop) => (
            <Typography>{prop}</Typography>
        ))}
    </div>)
}
function MapData({ data }) {
    return (<div style={{ width: "100%", backgroundColor: "#f00" }}>
        {
            data.length>0?<KMap zoom={10} center={{ latitude: data[0].latitude, longitude: data[0].longitude }}>
                {
                    data.map((prop) => (

                        <Marker position={[prop.latitude, prop.longitude]}>
                            <Popup>
                                {prop.latitude + ',' + prop.longitude}
                            </Popup>
                        </Marker>
                    ))
                }
            </KMap>:null
        }
    </div>)
}

function ImageData({ data }) {
    let { getFile } = useAPI();
    return <div>
        <Grid container spacing={1}>
            {
                data.map((prop) => (
                    <Grid item xs={12} sm={6} md={6}>
                        <img alt={prop} src={getFile(prop.replace("public/",""))} style={{width:"100%"}}/>
                    </Grid>
                ))
            }
        </Grid>
    </div>
}
function FileData({ data, name }) {
    let { getFile } = useAPI();
    return <div>
        <Stack container>
            {
                data.map((prop) => (
                    <Card style={{ margin: 3 }}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <div>
                                <Typography>{name}</Typography>
                            </div>
                            <a href={getFile(prop)} download>
                                <Button color='success'> Download</Button>
                            </a>
                        </Stack>
                    </Card>
                ))
            }
        </Stack>
    </div>
}
function ListTextData({ data }) {
    return (
        <div>
            {
                data.map((prop) => (
                    <div>
                        <Card style={{ margin: 3 }}>
                        </Card>
                    </div>
                ))
            }
        </div>
    )
}
function MaplocationData({ data }) {
    return (
        <div>
            <div id="map">

            </div>
        </div>
    )
}
function TabularInput({ dat = { rows: [], answer: [], cols: [] } }) {
    return (
        <div>
            <Table>
                <TableRow>
                    <TableCell>Sub-Activity</TableCell>
                    {dat.cols.map((prop, key) => (
                        <TableCell>

                            <TableHead key={key}>{prop}</TableHead>
                        </TableCell>
                    ))}
                </TableRow>
                {
                    dat.answer.map((prop, key) => (
                        <TableRow>
                            {
                                prop.map((pr, ket) => (
                                    <TableCell>{pr}</TableCell>
                                ))
                            }
                        </TableRow>
                    ))
                }
            </Table>
        </div>
    )
}
function GetPreview({ type, data,name }) {
    switch (type) {
        case 'text':
        case 'textarea':
        case 'number':
        case 'date':
        case 'select':
        case 'DatabaseSelect':
            return <TextData data={data} />;
        case 'checkbox':
            return <TextData data={data[0]==="0"?["No"]:["Yes"]} />;
        case 'maplocation':
            return <MapData data={data} />;
        case 'location':
            return <MapData data={data} />;
        case 'image':
            return <ImageData data={data} />;
        case 'file':
            return <FileData name={name} data={data} />;
        case 'listtext':
            return <ListTextData data={data} />;
        case 'TabularInput':
            return null;
        case 'Pair':
            return <ResponseTabularView actions={[]} responses={data} minHeight={"unset"}/>
        case 'street':
            return <TextData data={data}/>
        // return <TabularInput dat={data} />
        default:
            return <Alert color='danger' variant='soft'><KIcon name='close' /> Question couldnt be Identified</Alert>
    }
}

export { TextData, MapData, ImageData, FileData, ListTextData, MaplocationData, TabularInput, GetPreview }

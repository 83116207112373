import * as React from "react";
import { ArrowLeft, Back, HamburgerButton, Logout, MenuFold, User } from "@icon-park/react";
import { useNavigate } from "react-router";
import { useAPI } from "../controllers/ContextProvider";
import { closeNav, openNav } from "./sidebar";
import { Card, IconButton,Button, useColorScheme, useTheme, Sheet } from "@mui/joy";
import { Stack } from "@mui/joy";
import { ColorMode } from "./components";

export function ButtonAppBar() {
  let nav = useNavigate();
  let { setToken, setNav, navIsOpen, app } = useAPI();
  let {mode} = useColorScheme()
  let {colorSchemes} = useTheme()
  return (
    <Sheet
      style={{
        borderRadius: 0,
        height: 57,
        padding: 2,
        backgroundColor:colorSchemes.dark.palette.background.backdrop
      }}
    >
      {/* <AppBar position="static"> */}
      {/* <Toolbar> */}
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"}>
          <span style={{ width: 10 }}></span>
          <IconButton
            size="large"
            edge="start"
            onClick={() => {
              navIsOpen ? closeNav(app.navbar) : openNav(app.navbar);
              setNav(!navIsOpen);
            }}
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <HamburgerButton size={20}/>
          </IconButton>
          <IconButton
            onClick={() => {
              nav(-1);
            }}
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <ArrowLeft size={20}/>
          </IconButton>
        </Stack>
        <Stack spacing={2} alignItems={"center"} direction={"row"}>
          <ColorMode />
          <Button
            style={{ justifyContent: "center", }}
            variant="soft"
            color="warning"
            onClick={() => {
              setToken(null);
              nav("/", { replace: true });
            }}
          >
            {" "}
            <User style={{ marginRight: 5 }} size={20} />
            Log out
          </Button>
        </Stack>
        {/* </Toolbar> */}
        {/* </AppBar> */}
      </Stack>
    </Sheet>
  );
}

import { Card, Input, Stack, Typography, Button } from "@mui/joy";
import * as React from "react";
import { useAPI } from "../controllers/ContextProvider";

export default function AccountPage() {
  let { getFile, user, upload } = useAPI();
  const [currentView, setCurrentView] = React.useState("user");
  const LabelText = ({ placeholder, value }) => (
    <Stack>
      <Typography level="body-sm">{placeholder}</Typography>
      <Typography level="body-md">{value}</Typography>
    </Stack>
  );
  const ViewFier = ({ userPhoto, user, changeView, view, post }) => {
    switch (view) {
      case "user":
        return <User photo={userPhoto} user={user} />;
      case "chpwd":
        return <ChangePWD post={post} changeView={changeView} />;
    }
  };
  const ChangePWD = ({ post, changeView }) => {
    const [pwd, setPwd] = React.useState({ pwd: "", cpwd: "", npwd: "" });
    const [error, setError] = React.useState("");
    const onChange = (e, v) => {
      let p = pwd;
      p[e] = v;
      setPwd(p);
    };
    const changePwd = () => {
      let f = new FormData();
      if (pwd.pwd !== "" && pwd.cpwd !== "" && pwd.npwd !== "") {
        f.append("pwd", pwd.pwd);
        f.append("npwd", pwd.npwd);
        f.append("cpwd", pwd.cpwd);
        post("auth/changePassword", f).then((r) => {
          if (r.status) {
            if (r.data.status) {
              changeView("user");
            } else {
              setError(r.data.message);
            }
          }
        });
      } else {
        setError("Please check all fields");
      }
    };
    return (
      <Stack spacing={3} padding={5}>
        <Typography color="danger" textAlign={"center"}>
          {error}
        </Typography>
        <Input
          placeholder="Current password"
          type="password"
          onChange={(e) => {
            onChange("pwd", e.target.value);
          }}
        />
        <Input
          placeholder="Current password"
          type="password"
          onChange={(e) => {
            onChange("npwd", e.target.value);
          }}
        />
        <Input
          placeholder="Current password"
          type="password"
          onChange={(e) => {
            onChange("cpwd", e.target.value);
          }}
        />
        <Stack direction={"row"} spacing={1}>
          <Button
            onClick={() => {
              changePwd();
            }}
          >
            Change Password
          </Button>
          <Button
            onClick={() => {
              changeView("user");
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    );
  };
  const User = ({ photo, user }) => {
    return (
      <Stack>
        <Stack spacing={3} padding={5}>
          <LabelText placeholder="Fullname" value={user.name} />
          <LabelText placeholder="Email Address" value={user.email} />
          <LabelText placeholder="Created date" value={user.created_at} />
          <Stack>
            <Button
              onClick={() => {
                setCurrentView("chpwd");
              }}
            >
              Change Password
            </Button>
          </Stack>
        </Stack>
      </Stack>
    );
  };
  return (
    <Stack justifyContent={"center"} alignItems={"center"}>
      <Card>
        <ViewFier
          userPhoto={""}
          post={upload}
          changeView={setCurrentView}
          view={currentView}
          user={user}
        />
      </Card>
    </Stack>
  );
}

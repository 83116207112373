import * as React from "react";
import ApexCharts from "apexcharts";
import { useColorScheme } from "@mui/joy";

function Chartifier({ type, data }) {
  switch (type) {
    case "Bar":
      return <BarChart data={data} />;
    case "Line":
    // return <LineChart data={data} />
  }
}

function BarChart({ data }) {
  return null;
}
function LineChartTimeSeries({ name, data, height = 350, chartId = "jiiji" }) {
  let {mode} = useColorScheme()
  // let chartId = "chart"+Date.now()
  let options = {
    grid: {
        row: {
          colors: ['#00000000', '#00000000', '#00000000']
        },
        column: {
          colors: ['#00000000', '#00000000', '#00000000']
        },
        yaxis:{
          lines: {
            show: false,
          }
        },
        xaxis:{
          lines: {
            show: false,
          }
        },
      },
    chart: {
      type: "area",
      height: height,
      zoom: {
        autoScakeYaxis: true,
      },
    },
    series: [
      {
        name: name,
        data: [
          {
            x: new Date("2012-03-12").getTime(),
            y: 0,
          },
          {
            x: new Date("2018-03-12").getTime(),
            y: 76,
          },
          {
            x: new Date("2018-03-16").getTime(),
            y: 45,
          },
          {
            x: new Date("2020-02-16").getTime(),
            y: 98,
          },
          {
            x: new Date("2021-02-16").getTime(),
            y: 118,
          },
          {
            x: new Date("2022-02-16").getTime(),
            y: 181,
          },
        ],
      },
      {
        name: name,
        data: [
          {
            x: new Date("2012-03-12").getTime(),
            y: 0,
          },
          {
            x: new Date("2018-03-12").getTime(),
            y: 26,
          },
          {
            x: new Date("2018-03-16").getTime(),
            y: 15,
          },
          {
            x: new Date("2020-02-16").getTime(),
            y: 28,
          },
        ],
      },
    ],
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
      style: "hollow",
    },
    xaxis: {
      type: "datetime",
      min: new Date("01 Mar 2012").getTime(),
      lines: {
        show: false,
      }
    },
    
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade:mode,
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
        colors:[["#000","#f00"]]
      },
    },
  };
  React.useEffect(() => {
    let chart = new ApexCharts(document.getElementById(chartId), options);
    chart.render();
  }, []);
  return <div id={chartId}></div>;
}
function dateToTime(date, separator) {
  let myDate = date.split(separator);
  var newDate = new Date(myDate[2], myDate[1] - 1, myDate[0]);
  return newDate.getTime();
}
function chartCompiler(col, cat, data) {
  let p = [];
  let d = [];
  data.forEach((t) => {
    p.push(t[col]);
    d.push(t[cat]);
  });
  return { cat: d, data: p };
}
export { LineChartTimeSeries };

export class KUtility{
    static getById(data,key,value){
        for(let i = 0; i<data.length;i++){
            if(data[i][key]===value){
                return data[i];
            }
        }
        return null;
    }
    static isMobile(){
        if(window.innerWidth< 500){
            return true
        }
        return false
    }
}
import * as React from "react";
import { useAPI } from "../../controllers/ContextProvider";
import { DashboardCard, DashboardLoader } from "./dashboardItems";
import { Grid } from "@mui/material";
import { LineChartTimeSeries } from "../../components/chartifier";
import { Card, Typography } from "@mui/joy";

export default function DashboardPage() {
  let { get } = useAPI();
  const [page, setPage] = React.useState({ dashboards: [] });
  React.useEffect(() => {
    get("dashboard/page/getPage/2").then((r) => {
      setPage(r.data);
    });
  }, []);
  return (
    <div>
      <Grid container spacing={1}>
        {page.dashboards.map((prop, key) => (
          <Grid item xs={12} md={4} lg={4}>
            <DashboardLoader data={prop} />
          </Grid>
        ))}
        {page.dashboards.map((prop, key) => (
          <Grid item xs={12} md={4} lg={4}>
            <DashboardLoader data={prop} />
          </Grid>
        ))}
        <Grid item xs={12} md={6}>
            <DashboardCard title={"Sales"} value="">
            <LineChartTimeSeries />
            </DashboardCard>
        </Grid>
      </Grid>
    </div>
  );
}

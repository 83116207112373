import { Button, Card, CardContent } from '@mui/joy'
import { Stack } from '@mui/material'
import * as React from 'react'
import { useAPI } from '../../controllers/ContextProvider'
import { Download } from '@icon-park/react'
import KTable from '../../components/table'

export default function InventoryManager(){
    let {get,post,setFormId,setShowForm,business,setAdditionalFormData} = useAPI()
    const [products,setProducts] = React.useState([]);
    React.useEffect(()=>{
        get(`accounting/list/business/${business.id}/inventories`).then(r=>{
            setProducts(r.data);
        })
    },[])
    return(
        <div>
            <Stack marginBottom={1} direction={"row"} justifyContent={"space-between"}>
                <Button onClick={()=>{setFormId(5);setShowForm(true);setAdditionalFormData({business:business.id})}}>Add Inventory</Button>
                <Button startDecorator={<Download/>} onClick={()=>{setFormId(3);setShowForm(true);setAdditionalFormData({business:business.id})}}>Import Project Inventory List</Button>
            </Stack>
                <Card>
                    <CardContent>
                        <KTable rows={products} columns={
                            [
                                {field:'id',headerName:'#',width:30},
                                {field:'name',headerName:'Inventory name',width:300},
                                {field:'price',headerName:'Price',width:300},
                                {field:'quantity',headerName:'Quantity in stock',width:200},
                                {field: 'action', headerName: 'Action', width:100}
                            ]
                            }/>
                    </CardContent>
                </Card>
        </div>
    )
}
import { Typography } from "@mui/joy";
import * as React from "react";
import { InfinitySpin, LineWave, RotatingLines } from "react-loader-spinner";
import Card from "@mui/joy/Card";
import { useAPI } from "../controllers/ContextProvider";
import { KUtility } from "./utility";
export function KLoader({ size = "large" }) {
  let { navIsOpen } = useAPI();
  const styles = {
    large: {
      height: "100%",
      backdropFilter: "blur(5px)",
      display: "none",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#555e6812",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 233,
    },
    window: {
      display: "none",
      height: "100%",
      zIndex: 233,
      backdropFilter: "blur(5px)",
      backgroundColor: "#555e6812",
      justifyContent: "center",
      alignItems: "center",
      ...(KUtility.isMobile()?{...({
        position: "fixed",
        top: 0,
        left: 0,
        width:window.innerWidth,
        bottom: 0,
      })}:{...({
        position:"absolute",
        width:"100%",
      })})
    },
  };
  return (
    <div id={"kloader" + size} style={styles[size]}>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <InfinitySpin
          width="200"
          color="#0078d7"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
        <Typography
          style={{ textAlign: "center" }}
          id={"kloadmessage" + size}
        ></Typography>
      </div>
    </div>
  );
}

export class Loading {
  static showLoader(message, size = "large") {
    document.getElementById("kloader" + size).style.display = "flex";
    document.getElementById("kloadmessage" + size).innerHTML = message;
  }
  static hideLoader(size = "large") {
    setTimeout(() => {
      document.getElementById("kloader" + size).style.display = "none";
    }, 2000);
  }
  static setMessage(message, size = "large") {
    document.getElementById("kloadmessage" + size).innerHTML = message;
  }
}

import { Card, Divider, LinearProgress, TabPanel } from "@mui/joy";
import { Grid, Stack, Typography } from "@mui/joy";
import * as React from "react";
import KTabPanel from "../tabpanel";
import { ProjectTask } from "./task";
import { useLocation } from "react-router";
import {
  Dashboard,
  Expenses,
  List,
  LocalPin,
  PersonalPrivacy,
  User,
} from "@icon-park/react";
import Expenditures from "../accounting/expenses";
import AnimatedDiv from "../../components/Animated";

export default function ProjectView() {
  const data = useLocation().state;
  return (
    <Card>
      <div>
        <KTabPanel
          tabheads={[
            "Project Dashboard",
            "Project Tasks",
            "Project Expenditure",
          ]}
          tabcomps={[
            <TabPanel value={0}>
              <ProjectOverview data={data} />
            </TabPanel>,
            <TabPanel value={1}>
              <ProjectTask project={data} />
            </TabPanel>,
            <TabPanel value={2}>
              <Expenditures ptype={data.project_type} project={data.id} />
            </TabPanel>,
          ]}
        />
      </div>
    </Card>
  );
}
function ProjectOverview({ data }) {
  const getExp = (d) => {
    let exp = (d.total_expenditure * 100) / d.price;

    if (exp < 80) {
      return ["primary", "Normal Expenditure level", exp];
    } else if (exp > 80 && exp <= 100) {
      return ["warning", "You are reaching maximum project cost", exp];
    } else {
      return ["danger", "Expenditures level has surpassed project cost", exp];
    }
  };
  return (
    <div>
      <Grid container className="animate__animated animate__fadeInDown">
        <Grid item xs={12} md={4}>
          <Stack direction={"row"} alignItems={"center"}>
            <div
              style={{
                padding: 10,
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Dashboard style={{ fontSize: 40 }} />
            </div>
            <div>
              <Typography variant="h6">Project Name</Typography>
              <Typography>{data.name}</Typography>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack direction={"row"} alignItems={"center"}>
            <div
              style={{
                padding: 10,
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LocalPin style={{ fontSize: 40 }} />
            </div>
            <div>
              <Typography variant="h6">Project Location</Typography>
              <Typography>{data.location}</Typography>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack direction={"row"} alignItems={"center"}>
            <div
              style={{
                padding: 10,
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PersonalPrivacy style={{ fontSize: 40 }} />
            </div>
            <div>
              <Typography variant="h6">Site Engineer</Typography>
              <Typography>{data.mason}</Typography>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack direction={"row"} alignItems={"center"}>
            <div
              style={{
                padding: 10,
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Expenses style={{ fontSize: 40 }} />
            </div>
            <div>
              <Typography variant="h6">Project Cost</Typography>
              <Typography>{data.price} TZS</Typography>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack direction={"row"} alignItems={"center"}>
            <div
              style={{
                padding: 10,
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Expenses style={{ fontSize: 40 }} />
            </div>
            <div>
              <Typography variant="h6">Project Total Expenditure</Typography>
              <Typography>{data.total_expenditure} TZS</Typography>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack direction={"row"} alignItems={"center"}>
            <Card
              variant="soft"
              style={{
                padding: 10,
                height: 50,
                width: 50,
                justifyContent: "center",
                alignItems: "center",
                margin: 4,
              }}
            >
              <Expenses style={{ fontSize: 40 }} />
            </Card>
            <div>
              <Typography variant="h6">Project Profit</Typography>
              <Typography>{data.price - data.total_expenditure} TZS</Typography>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack direction={"row"} alignItems={"center"}>
            <div
              style={{
                padding: 10,
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <List style={{ fontSize: 40 }} />
            </div>
            <div>
              <Typography variant="h6">Project description</Typography>
              <Typography>{data.description}</Typography>
            </div>
          </Stack>
        </Grid>
        <ClientDetails data={data} />
      </Grid>
      <Divider />
      <Typography
        fontWeight={"bold"}
        style={{ marginTop: 10, marginBottom: 10 }}
        variant="h5"
      >
        Project Progress
      </Typography>
      <Divider />
      <Grid container spacing={5}>
        <Grid item xs={6} justifyContent={"center"}>
          <Stack justifyContent={"space-between"} direction={"row"}>
            <Typography>
              {data.progress.cumulative}/{data.progress.total}
            </Typography>
            <Typography fontWeight={"bold"}>
              {data.progress.progress.toFixed(2)} %
            </Typography>
          </Stack>
          <LinearProgress
            thickness={10}
            determinate
            value={data.progress.progress}
          />
        </Grid>
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">Expenditure</Typography>
          <Stack justifyContent={"space-between"} direction={"row"}>
            <Typography color={getExp(data)[0]}>{getExp(data)[1]}</Typography>
            <Typography fontWeight={"bold"}>{getExp(data)[2].toFixed(2)} %</Typography>
          </Stack>
          <LinearProgress
            thickness={10}
            determinate
            color={getExp(data)[0]}
            value={getExp(data)[2].toFixed(2)}
          />
          {/* <Alert startDecorator={<Info />} ></Alert> */}
        </Grid>
      </Grid>
    </div>
  );
}

function ClientDetails({ data }) {
  return (
    <div>
      <AnimatedDiv name={"fadeInUp"}>
        <Grid container>
          <Grid xs={6} md={6}>
            <Stack direction={"row"} alignItems={"center"}>
              <div
                style={{
                  padding: 10,
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <User style={{ fontSize: 40 }} />
              </div>
              <div>
                <Typography>Client Name</Typography>
                <Typography>{data.attributes.client_name}</Typography>
              </div>
            </Stack>
          </Grid>
          <Grid xs={6} md={6}>
            <Stack direction={"row"} alignItems={"center"}>
              <div
                style={{
                  padding: 10,
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <User style={{ fontSize: 40 }} />
              </div>
              <div>
                <Typography>Client Email Address</Typography>
                <Typography>{data.attributes.client_description}</Typography>
              </div>
            </Stack>
          </Grid>
          <Grid xs={6} md={6}>
            <Stack direction={"row"} alignItems={"center"}>
              <div
                style={{
                  padding: 10,
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <User style={{ fontSize: 40 }} />
              </div>
              <div>
                <Typography>Client Phone</Typography>
                <Typography>{data.attributes.client_phone}</Typography>
              </div>
            </Stack>
          </Grid>
          <Grid xs={4} md={4}>
            <Stack direction={"row"} alignItems={"center"}>
              <div
                style={{
                  padding: 10,
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LocalPin style={{ fontSize: 40 }} />
              </div>
              <div>
                <Typography>Client Address</Typography>
                <Typography>{data.attributes.client_address}</Typography>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </AnimatedDiv>
    </div>
  );
}

import { Card, CardContent, Button, Alert, ButtonGroup } from "@mui/joy";
import * as React from "react";
import { PaperMoney, Add, Excel, DownloadOne } from "@icon-park/react";
import { Grid } from "@mui/material";
import ResponseTabularView from "../responsetabular";
import { useAPI } from "../../controllers/ContextProvider";
import { useNavigate, useParams } from "react-router";

export default function FormTab({ form = 0, child = false, parent = null }) {
  const [responses, setResponses] = React.useState({
    form: { definition: "" },
    data: [],
  });
  let { fid } = useParams();
  let nav = useNavigate();
  let {
    get,
    post,
    setFormId,
    setShowForm,
    business,
    setAdditionalFormData,
    updateFormRequest,
    showForm,
    APIURL,
    appConfig,
  } = useAPI();
  React.useEffect(() => {
    if (showForm === false) {
      getResponses();
    }
    // alert(fid)
  }, [fid, showForm]);
  const retrieveByIndex = (index) => {
    for (let i = 0; i < responses.data.length; i++) {
      if (responses.data[i].tag === index) {
        return responses.data[i];
      }
    }
  };
  const getResponses = () => {
    if (form !== 0) {
      fid = form;
    }
    let endpoint = `forms/list/responses/byTopic/${fid}/groupByTag`;
    if (child) {
      endpoint = `forms/list/responses/byTopicAndTag/${fid}/${parent}/groupByTag`;
    }
    get(endpoint).then((r) => {
      setResponses(r.data);
    });
  };
  return (
    <div>
      <Card>
        <CardContent>
          <br />
          <Grid container spacing={1} style={{ marginBottom: 10 }}>
            <Grid item md={4} xs={12}>
              <Button
                fullWidth={false}
                onClick={() => {
                  if(child){
                    setAdditionalFormData({parentTag:parent})
                  }
                  else{
                    setAdditionalFormData({})
                  }
                  setFormId(fid);
                  setShowForm(true);
                }}
                startDecorator={<Add />}
              >
                Add New
              </Button>
            </Grid>
            <Grid item md={8} xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <ButtonGroup color="primary" variant="soft">
                  <Button
                    fullWidth={false}
                    onClick={() => {
                      setFormId(fid);
                      setShowForm(true);
                    }}
                    startDecorator={<Add />}
                  >
                    Add New
                  </Button>
                  <Button
                    onClick={() => {
                      var link = document.createElement("a");
                      link.href = APIURL + `forms/download/xls/${fid}/template`;
                      link.download = "filename";
                      link.click();
                    }}
                    startDecorator={<DownloadOne />}
                  >
                    Download Excel template (.xls)
                  </Button>
                  <Button
                    onClick={() => {
                      setFormId(appConfig.formConfig.xls_form);
                      setShowForm(true);
                      setAdditionalFormData({
                        form: fid,
                        tag: child ? parent : null,
                      });
                    }}
                    startDecorator={<Excel />}
                  >
                    Import from Excel (.xls)
                  </Button>
                </ButtonGroup>
              </div>
            </Grid>
          </Grid>
          <ResponseTabularView
            onRowClick={(e) => {
              nav(
                responses.form.type === "api"
                  ? "/forms/api/preview"
                  : "/forms/response/"+e.tag+"/preview",
                { state: { data: e, form: responses.form } }
              );
            }}
            actions={responses.form.actions}
            responses={responses.data}
          />
        </CardContent>
      </Card>
    </div>
  );
}

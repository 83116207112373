import * as React from "react";
import { useRoutes } from "react-router";
import FormResponses from "./panels/forms/responses";
import ManagementWindow from "./windows/management";
import AuthForm from "./pages/authform";
import ReportsManager from "./panels/forms";
import ResponsePreview from "./panels/forms/responsepreview";
import UsersPanel from "./panels/forms/users";
import ProjectViewer from "./pages/project";
import Logout from "./pages/logout";
import { FormBuilder } from "./panels/forms/formbuilder";
import AcsolarReportPreview from "./panels/forms/acsolarreport";
import RegisterUSer from "./pages/regform";
import ProjectView from "./panels/projects/projectview";
import Expenditures, { ExpensesPreview } from "./panels/accounting/expenses";
import InventoryManager from "./panels/accounting/inventory";
import ProjectList from "./panels/projects/projects";
import Tribunals from "./panels/accounting/tribunals";
import FormTab from "./panels/forms/formtab";
import AuthFormAcsolar from "./pages/authform_acsolar";
import { FeedbackPreviewer } from "./panels/forms/feedbackPreview";
import { PermissionHome } from "./panels/permissions/permissionsManager";
import DashboardPage from "./panels/dashboard/dashboardPage";
import APIPreview from "./panels/forms/apiPreview";
import AdminWindow from "./windows/admin";
import { BusinessList, BusinessView } from "./panels/business/businessList";
import { AdsList, AdView } from "./panels/business/ads";
import WelcomePage from "./pages/welcome";
import FormCreator, { FormStudio } from "./panels/forms/form_creator";
import Four04 from "./pages/404";
import AccountPage from "./pages/account";
import CompactLogin from "./pages/auth_compact";
import LandingPage, { MyBusinesses, SASPackages } from "./pages/landing";
import BusinessWorkspace from "./windows/workspace";
export default function AppRoute() {
  return useRoutes([
    {
      element: <CompactLogin />,
      path: "/",
    },
    {
      element: <WelcomePage />,
      path: "/welcome/:tag",
    },
    {
      element: <RegisterUSer />,
      path: "/register",
    },
    {
      element: <LandingPage />,
      path: "/landing",
      children: [
        { element: <MyBusinesses />, path: "businesses" },
        { element: <SASPackages />, path: "market" },
      ],
    },
    {
      element: <BusinessWorkspace />,
      path: "/business",
      children: [
        { path: "page/:tag/preview", element: <FeedbackPreviewer /> },
        { path: "tab/:fid", element: <FormTab /> },
      ],
    },
    {
      element: <ManagementWindow />,
      path: "/forms",
      children: [
        { path: "dashboard", element: <DashboardPage /> },
        { path: "expenses/preview", element: <ExpensesPreview /> },
        { path: "expenses", element: <Expenditures /> },
        { path: "projects", element: <ProjectList /> },
        { path: "preview", element: <AcsolarReportPreview /> },
        { path: "project", element: <ProjectView /> },
        { path: "tribunals", element: <Tribunals /> },
        { path: "users", element: <UsersPanel /> },
        { path: "formbuilder", element: <FormBuilder /> },
        { path: "inventory", element: <InventoryManager /> },
        { path: "formview/:fid", element: <FormTab /> },
        { path: "permissions", element: <PermissionHome /> },
        { path: "response/:tag/preview", element: <FeedbackPreviewer /> },
        { path: "api/preview", element: <APIPreview /> },
        { path: "logout", element: <Logout /> },
        { path: "businesses", element: <BusinessList /> },
        { path: "business", element: <BusinessView /> },
        { path: "ads", element: <AdsList /> },
        { path: "ad", element: <AdView /> },
        { path: "studio", element: <FormStudio /> },
        { path: "builder", element: <FormCreator /> },
        { path: "account", element: <AccountPage /> },
        { path: "*", element: <Four04 /> },
      ],
    },
    {
      element: <AdminWindow />,
      path: "/admin",
      children: [],
    },
    {
      element: <Four04 />,
      path: "*",
    },
  ]);
}

import {
  Avatar,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Sheet,
  Stack,
  Typography,
  useTheme,
} from "@mui/joy";
import * as React from "react";
import { Outlet } from "react-router";
import KTable from "../components/table";
import { useAPI } from "../controllers/ContextProvider";
import { useNavigate } from "react-router";
import { KUtility } from "../components/utility";
import { Check, Plus, ShoppingBagOne } from "@icon-park/react";
import { AppBar } from "@mui/material";
import { ColorMode } from "../components/components";
import ParticleContainer from "../components/particle";
export default function LandingPage() {
  let { getFile, app } = useAPI();
  let nav = useNavigate();
  return (
    <div>
      <div style={{ padding: 10 }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div onClick={()=>{nav('/landing/businesses')}}>
            <Stack direction={"row"} alignItems={"center"}>
              <img src={getFile(app.logo)} style={{ height: 40, width: 40 }} />
              <Typography level="h3">Hotpot | {app.name} Pro</Typography>
            </Stack>
          </div>
          <Stack direction={"row"} spacing={2}>
            <ColorMode />
            <Button
              variant="solid"
              color="primary"
              startDecorator={<ShoppingBagOne size={30} />}
              onClick={() => {
                nav("/landing/market");
              }}
            >
              Marketplace
            </Button>
            <Button
              variant="soft"
              color="primary"
              startDecorator={
                <Avatar size="sm" src={getFile("icons/user.png")} />
              }
              onClick={() => {}}
            >
              My Account
            </Button>
          </Stack>
        </Stack>
        <Sheet
          style={{
            height: window.innerHeight - 104,
            overflow: "overlay",
            padding: 20,
          }}
        >
          <Outlet />
        </Sheet>
      </div>
    </div>
  );
}

export function MyBusinesses() {
  let { get } = useAPI();
  let nav = useNavigate();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    get("business/myBusinesses").then((r) => {
      setData(r.data);
    });
  }, []);
  return (
    <div>
      <Divider slotProps={{ root: { style: { marginTop: 10 } } }} />
      <Sheet>
        <Typography level="h2">My Businesses</Typography>
        <Button
          startDecorator={<Plus />}
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          Create new Business
        </Button>
        <KTable
          columns={[
            { field: "id", headerName: "Id" },
            { field: "name", headerName: "Name" },
            { field: "description", headerName: "Details" },
            { field: "country", headerName: "Country" },
          ]}
          rows={data}
          onRowClick={(e) => {
            nav("/business", {
              state: { business: KUtility.getById(data, "id", e.row.id) },
            });
          }}
        />
      </Sheet>
    </div>
  );
}
export function SASPackages() {
  return (
    <div>
      <ParticleContainer />
      <Typography level="h3">Marketplace</Typography>
      <Grid container gridRow={"revert"} spacing={2}>
        <Grid item xs={12} md={4}>
          <PackageLook />
        </Grid>
        <Grid item xs={12} md={4}>
          <PackageLook />
        </Grid>
        <Grid item xs={12} md={4}>
          <PackageLook />
        </Grid>
        <Grid item xs={12} md={4}>
          <PackageLook />
        </Grid>
      </Grid>
    </div>
  );
}
function PackageLook() {
  let theme = useTheme();
  const Item = ({ name }) => (
    <Stack direction={"row"} spacing={5}>
      <Check color={theme.colorSchemes.light.palette.success[500]} />
      <Typography>{name}</Typography>
    </Stack>
  );
  return (
    <div>
      <Card
        style={{
          backgroundColor: theme.colorSchemes.dark.palette.background.backdrop,
          backdropFilter: "blur(15px)",
        }}
      >
        <Typography textAlign={"center"} level="h2">
          Meneja Essential
        </Typography>
        <Typography>
          Manage your business with ease, Manager Manage your business with
          ease, Manager Manage your business with ease, Manager Manage your
          business with ease, Manager
        </Typography>
        <Divider />
        {[
          "Point of Sales",
          "Expenditure management",
          "Sales management",
          "Product management",
          "Warehouse management",
          "Stock Management",
          "Automated - Monthly reports",
        ].map((prop, key) => (
          <Item name={prop} />
        ))}
        <Divider />
        <Typography textAlign={"center"} level="h2">
          5,000 Tsh / 6 Month
        </Typography>
        <Divider />
        <Button color="primary" style={{ paddingTop: 20, paddingBottom: 20 }}>
          Subscribe
        </Button>
      </Card>
    </div>
  );
}

import "./App.css";
import { KLoader } from "./components/loader";
import { KContext } from "./controllers/ContextProvider";
import AppRoute from "./routes";
import { BrowserRouter } from "react-router-dom";
// import {FormBuilder} from './panels/forms/formbuilder';
import "animate.css";
import { SuccessDialog } from "./components/dialog";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { UnableToCompleteRequest } from "./components/components";
import { CssVarsProvider, extendTheme, Sheet } from "@mui/joy";

function App() {
  const theme = extendTheme({
    cssVarPrefix: "mode-toggle",
    colorSchemeSelector: ".demo_mode-toggle-%s",
    components: {
        JoyButton: {
          defaultProps: {
            size: 'md',
          },
          styleOverrides: {
            root: {
              borderRadius: '20px',
            },
          },
        },
      },
  });
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <KContext>
        <CssVarsProvider
          theme={theme}
           defaultMode="dark"
          modeStorageKey="mode-toggle-demo"
          //
          // set as root provider
          disableNestedContext
        >
          {/* <Display/> */}
          <Sheet>
            <BrowserRouter>
              <div className="App">
                <AppRoute />
                <UnableToCompleteRequest />
                <KLoader size={"large"} />
                <SuccessDialog />
                {/* <FormBuilder/> */}
              </div>
            </BrowserRouter>
          </Sheet>
        </CssVarsProvider>
      </KContext>
    </LocalizationProvider>
  );
}

export default App;
